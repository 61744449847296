import React from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultLayout } from '../components/layout/twentyfour/default';

export function AdminPage() {
  const location = useLocation();

  const [slashed, setSlashed] = React.useState(false);
  React.useEffect(() => {
    const endsWithSlash = location.pathname.endsWith('/');
    setSlashed(endsWithSlash);
    if (endsWithSlash) return;
    window.location.replace('/admin/');
  }, [location]);

  return (
    <DefaultLayout>
      {slashed ? `admin page (see mf-admin)` : `loading...`}
    </DefaultLayout>
  );
}
