import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useDataTableContext } from '../../components/commons/data-table/data-table-control';
import { FormController } from '../../components/commons/form/controller/form-controller';
import { Select } from '../../components/commons/form/select/select';
import { TextField } from '../../components/commons/form/textfield/textfield';
import { AggregatedSyncStatusEnum } from '../../enums/sync-status';
import { useAppContext } from '../../hooks/useApp';
import { useInitialFilterState } from './aggregated-summary-filter.state';

export function AggregatedSummaryFilter() {
  const { initState } = useAppContext();
  const tableContext = useDataTableContext();
  const { setFilter } = tableContext;

  const initialState = useInitialFilterState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onChange = (data: any) => { }

  const onSubmit = React.useCallback(async (data: any) => {
    const f: any = {};
    const keys = ['searchKey', 'uploadedBy', 'syncStatus'];
    keys.forEach((key) => {
      f[key] = data[key] ? data[key] : undefined;
    });
    setFilter(f);
    initState.setState('aggregated-filter', f);
    handleClose();
  }, [setFilter, handleClose, initState]);

  /** FOr badge number */
  const count = React.useMemo(() => {
    const f: any = { ...initialState, ...tableContext.filter };
    const keys = ['searchKey', 'uploadedBy', 'syncStatus'];
    let c = 0;
    keys.forEach((key) => {
      if (f[key] !== null && f[key] !== undefined && f[key] !== '') {
        c += 1;
      }
    });
    return c;
  }, [tableContext.filter, initialState])

  return (
    <>
      <Badge color="success" overlap="circular" badgeContent={count}>
        <IconButton onClick={handleOpen}>
          <FilterAltIcon />
        </IconButton>
      </Badge>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Filter</DialogTitle>
        <FormController
          onChange={onChange}
          onSubmit={onSubmit}
          useFormProps={{
            defaultValues: { ...initialState, ...tableContext.filter },
          }}
        >
          <DialogContent>
            <Box sx={{ pt: '10px' }}>
              <Stack spacing={2}>
                <TextField
                  name="searchKey"
                  label="Search Key"
                />
                <TextField
                  name="uploadedBy"
                  label="Uploaded By"
                />
                <Select
                  name="syncStatus"
                  label="Sync Status"
                  options={['', ...Object.values(AggregatedSyncStatusEnum)]}
                />
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" role="button">Save</Button>
          </DialogActions>
        </FormController >
      </Dialog>
    </>
  );
}
