import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "itemReceiptId",
      accessorKey: "itemReceiptId",
      header: "Item Receipt ID",
    },
    {
      id: "shiftReportId",
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: "dsrId",
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: "subsidiary",
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "stationName",
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: "shiftCode",
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: "date",
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "dateTime",
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "location",
      accessorKey: "location",
      header: "Location",
    },
    {
      id: "poNumber",
      accessorKey: "poNumber",
      header: "PO Number",
    },
    {
      id: "deliveryDateTime",
      accessorKey: "deliveryDateTime",
      header: "Delivery Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "refPlateNumber",
      accessorKey: "refPlateNumber",
      header: "Ref Plate Number",
    },
    {
      id: "attendant",
      accessorKey: "attendant",
      header: "Attendant",
    },
    {
      id: "crew",
      accessorKey: "crew",
      header: "Crew",
    },
    {
      id: "cashier",
      accessorKey: "cashier",
      header: "Cashier",
    },
    {
      id: "trainee",
      accessorKey: "trainee",
      header: "Trainee",
    },
    {
      id: "itemCode",
      accessorKey: "itemCode",
      header: "Item Code",
    },
    {
      id: "productName",
      accessorKey: "productName",
      header: "Product Name",
    },
    {
      id: "beforeReceivingCm",
      accessorKey: "beforeReceivingCm",
      header: "Before Receiving Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "beforeReceivingQty",
      accessorKey: "beforeReceivingQty",
      header: "Before Receiving Qty",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "quantityDelivered",
      accessorKey: "quantityDelivered",
      header: "Quantity Delivered",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "afterReceivingCm",
      accessorKey: "afterReceivingCm",
      header: "After Receiving Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "afterReceivingQty",
      accessorKey: "afterReceivingQty",
      header: "After Receiving Qty",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "variance",
      accessorKey: "variance",
      header: "Variance",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "percentVariance",
      accessorKey: "percentVariance",
      header: "Percent Variance",
      cell: (info) => <DisplayFloat isPercent>{info.getValue() as string}</DisplayFloat>,
    },
  ];

  return columns;
}
