import axios from "axios";
import ApiPath from "../enums/api-path";
import { BasicQueryApi, ListApiFn, ListApiProps } from "./api.type";

export interface GetLocationQueryApi extends BasicQueryApi {
  subsidiary?: string;
}

export type LocationResponse = {
  gcashBranchName?: string[],
  locationId: number,
  mayaMid?: string[],
  stationCode: string,
  stationName: string,
  subsidiary: string,
  subsidiaryId: number;
}

export const getLocationList: ListApiFn<LocationResponse> = async (props: ListApiProps<GetLocationQueryApi>) => {
  let { query = {} } = props;
  const r = await axios.get(`${ApiPath.Location}`, { params: query });
  return r;
};
