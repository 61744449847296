import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const DsrErrors = ({ errors }: any) => {
  const cellHeaderStyle = { color: 'white !important', whiteSpace: 'nowrap' };
  const cellRowStyle = { whiteSpace: 'nowrap' };

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: '1000px', overflowX: 'auto' }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'red', ...cellHeaderStyle }}>
            <TableCell sx={cellHeaderStyle}>Sheet Name</TableCell>
            <TableCell sx={cellHeaderStyle}>Row #</TableCell>
            <TableCell sx={cellHeaderStyle}>Error Code</TableCell>
            <TableCell sx={cellHeaderStyle}>Error Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors?.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" sx={cellRowStyle}>
                {row.sheet}
              </TableCell>
              <TableCell align="left" sx={cellRowStyle}>
                {row.row}
              </TableCell>
              <TableCell align="left" sx={cellRowStyle}>
                {row.errorCode}
              </TableCell>
              <TableCell align="left" sx={cellRowStyle}>
                {row.message}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DsrErrors;
