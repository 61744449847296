import { Theme, ThemeProvider } from '@mui/material/styles';
import { Stage } from '../environments/index.type';
import defaultTheme from '../themes/default';
import prodTheme from '../themes/prod';
import { useAppContext } from './useApp';

export function useTheme() {
  const Provider = (props: { children?: any }) => {
    const { theme: t } = useAppContext();

    let theme: Theme;
    if (t === Stage.PROD) {
      theme = prodTheme;
    } else {
      theme = defaultTheme;
    }

    return (
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    );
  }

  return { Provider }
}

export default useTheme;
