import { Environment, Stage } from "./index.type";

const env: Environment = {
  id: Stage.PROD,
  label: 'Production',
  apiUrl: 'https://api.jpci.io',
  firebaseConfig: {
    apiKey: 'AIzaSyATwlJ6nX3zlQCecUe3HgpAeDIEW3geVsA',
    authDomain: 'prod-ms-profile.firebaseapp.com',
    projectId: 'prod-ms-profile',
    storageBucket: 'prod-ms-profile.appspot.com',
    messagingSenderId: '394734570940',
    appId: '1:394734570940:web:847bf1504da016ba08efb9',
    measurementId: 'G-WZ3HED1TYD',
  },
};

export default env;
