import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "shiftReportId",
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: "dsrId",
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: "subsidiary",
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "stationName",
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: "cashier",
      accessorKey: "cashier",
      header: "Cashier",
    },
    {
      id: "shiftCode",
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: "shiftStart",
      accessorKey: "shiftStart",
      header: "Shift Start",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>
    },
    {
      id: "shiftEnd",
      accessorKey: "shiftEnd",
      header: "Shift End",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>
    },
  ];

  return columns;
}
