import { Box, Container, Grid } from '@mui/material';

export type DefaultLayoutProps = {
  children?: any;
};

export function CenteredLayout(props: DefaultLayoutProps) {
  const { children } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '90vh' }}
    >
      <Grid item xs={4}>
        <Container disableGutters>
          <Box sx={{ textAlign: 'center' }}>{children}</Box>
        </Container>
      </Grid>
    </Grid>
  );
}
