import { Button, Skeleton, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import {
  flexRender,
  getCoreRowModel,
  InitialTableState,
  useReactTable
} from '@tanstack/react-table';
import { GuyWithMagGlass } from 'images';
import React from 'react';
import Image from '../image/image';
import { useDataTableContext } from './data-table-control';

export interface DataTableProps {
  initialTableState?: InitialTableState;
  tableProps?: TableProps;
  tableRowProps?: TableRowProps;
  tableCellProps?: TableCellProps;
}

export function DataTable(props: DataTableProps) {
  const {
    initialTableState,
    tableProps,
    tableRowProps,
    tableCellProps,
  } = props;

  const context = useDataTableContext();
  const { commit } = context || {};

  const d = React.useMemo(() => {
    return context?.data || [];
  }, [context?.data]);

  const table = useReactTable({
    columns: context?.columns || [],
    data: d,
    getCoreRowModel: getCoreRowModel(),
    initialState: initialTableState,
  });

  const isLoading = React.useMemo(() => context.loading, [context.loading]);

  const unCommitedRow = React.useMemo(() => {
    if (context?.uncommited === 0 || isLoading) return undefined;
    return (
      <TableRow key='uncommited-row' {...tableRowProps}>
        <TableCell colSpan={table.getVisibleFlatColumns().length} {...tableCellProps}>
          <Box textAlign='center'>
            <Button sx={{ textTransform: 'none' }} variant='text' onClick={() => commit()}>
              Refresh Results
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    );
  }, [table, context?.uncommited, isLoading, commit, tableRowProps, tableCellProps]);

  const skeletonRows = React.useMemo(() => {
    return Array.from({ length: context?.perPage || 1 }, (x, i) => i).map((_, n) => {
      return (
        <TableRow key={n} {...tableRowProps}>
          {table.getVisibleFlatColumns().map((col) => (
            <TableCell key={col.id} {...tableCellProps}>
              <Skeleton animation="pulse" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }, [table, context?.perPage, tableRowProps, tableCellProps]);

  const noRecords = context?.data?.length === 0;
  const noRecordsFound = (
    <Box sx={{ padding: '50px' }}>
      <Stack spacing={2} alignItems="center">
        <Image src={GuyWithMagGlass} />
        <Typography variant="h5">Sorry, no results found</Typography>
      </Stack>
    </Box>
  );

  /**
   * set tableRef
   */
  React.useEffect(() => {
    context.tableRef.current = table;
  }, [context?.tableRef, table]);

  return (
    <Box>
      <TableContainer>
        <Table {...tableProps}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (!header.column.getIsVisible()) return undefined;
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {unCommitedRow}
            {isLoading
              ? skeletonRows
              : table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id} {...tableRowProps}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id} {...tableCellProps}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {!isLoading && noRecords ? noRecordsFound : <></>}
      </TableContainer>
    </Box>
  );
}
