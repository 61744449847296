import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'createdAt',
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'collectionId',
      accessorKey: "collectionId",
      header: "Collection ID",
    },
    {
      id: 'shiftReportId',
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: 'dsrId',
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: 'subsidiary',
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: 'stationCode',
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: 'stationName',
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: 'shiftCode',
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: 'date',
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'dateTime',
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'location',
      accessorKey: "location",
      header: "Location",
    },
    {
      id: 'collectionDateTime',
      accessorKey: "collectionDateTime",
      header: "Collection Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'amountCollected',
      accessorKey: "amountCollected",
      header: "Amount Collected",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'partialAmountCollected',
      accessorKey: "partialAmountCollected",
      header: "Partial Amount Collected",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'remarks',
      accessorKey: "remarks",
      header: "Remarks",
    },
    {
      id: 'siNum',
      accessorKey: "siNum",
      header: "SI Num",
    },
    {
      id: 'refName',
      accessorKey: "refName",
      header: "Ref Name",
    },
    {
      id: 'cashAccount',
      accessorKey: "cashAccount",
      header: "Cash Account",
    },
    {
      id: 'paymentMode',
      accessorKey: "paymentMode",
      header: "Payment Mode",
    },
    {
      id: 'refNumber1',
      accessorKey: "refNumber1",
      header: "Ref Number 1",
    },
    {
      id: 'refNumber2',
      accessorKey: "refNumber2",
      header: "Ref Number2",
    },
    {
      id: 'lookup',
      accessorKey: "lookup",
      header: "Lookup",
    },
  ];

  return columns;
}
