import { DefaultLayout } from '../components/layout/twentyfour/default';
import { UserProfileModule } from '../modules/user/profile.module';

export function UserProfilePage() {
  return (
    <DefaultLayout>
      <UserProfileModule />
    </DefaultLayout>
  )
}
