import { FirebaseOptions } from "firebase/app";

export enum Stage {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
};

export type Environment = {
  id: Stage,
  label?: string,
  apiUrl: string,
  firebaseConfig: FirebaseOptions,
}
