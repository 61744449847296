import { Grid, Stack } from "@mui/material";

import { DataTable } from "components/commons/data-table/data-table";
import { DataTableControl } from "components/commons/data-table/data-table-control";
import React from "react";
import {
  getDsrCashBreakdownList,
  GetDsrCashBreakdownListProps,
} from "../../../apis/dsr.api";
import { DataTablePagination } from "../../../components/commons/data-table/data-table-pagination";
import { useFormController } from "../../../components/commons/form/controller/form-controller";
import { useColumns } from "./cash-breakdown-column";
import { DsrCashBreakdownExport } from "./cash-breakdown-export";
import { useInitialFilterState } from "./cash-breakdown-filter.state";

export type DsrCashBreakdownListProps = {};

export function DsrCashBreakdownListModule(props: DsrCashBreakdownListProps) {
  const columns = useColumns();
  const initialFilterState = useInitialFilterState();
  const formController = useFormController();

  const [filter, setFilter] = React.useState<
    GetDsrCashBreakdownListProps["query"]
  >({});

  const onChange = React.useCallback((data: any) => {
    setFilter({
      subsidiary: data.subsidiary === "" ? undefined : data.subsidiary,
      stationCode: data.location?.stationCode,
      shiftCode: data.shiftCode,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }, []);

  React.useEffect(() => {
    onChange(formController.getValues());
    const { unsubscribe } = formController.watch(onChange);
    return () => unsubscribe();
  }, [onChange, formController]);

  return (
    <>
      <DataTableControl
        columns={columns}
        listFn={getDsrCashBreakdownList}
        initialApiProps={initialFilterState}
        autoCommit={false}
        filter={filter}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <DsrCashBreakdownExport />
              </Stack>
            </Grid>
          </Grid>

          <DataTable
            initialTableState={{
              columnVisibility: {
                dsrId: false,
                // date: false,
                createdAt: false,
                shiftReportId: false,
                location: false,
                cashBreakdownId: false,
                // category: false,
              },
            }}
          />
          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
