// useDialog.ts
import { useState } from 'react';

interface DialogState {
  open: boolean;
  title: string;
  message: string;
  htmlContent: React.ReactNode;
}

/**
 * @deprecated
 */
const useDialog = () => {
  const [dialog, setDialog] = useState<DialogState>({
    open: false,
    title: '',
    message: '',
    htmlContent: '',
  });

  const show = (
    title: string,
    message: string,
    htmlContent: React.ReactNode
  ) => {
    setDialog({ open: true, title, message, htmlContent });
  };

  const close = () => {
    setDialog((prev) => ({ ...prev, open: false }));
  };

  return {
    dialog,
    show,
    close,
    setDialog,
  };
};

export default useDialog;
