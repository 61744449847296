import { Environment, Stage } from "./index.type";

const env: Environment = {
  id: Stage.DEV,
  label: 'Test/Sandbox',
  apiUrl: 'https://dev.api.jpci.io',
  firebaseConfig: {
    apiKey: 'AIzaSyA9bhiEJm6W66H_9zi51ZokcReuX7uuymE',
    authDomain: 'dev-ms-profile.firebaseapp.com',
    projectId: 'dev-ms-profile',
    storageBucket: 'dev-ms-profile.appspot.com',
    messagingSenderId: '152999617901',
    appId: '1:152999617901:web:b28670afde6de8afa5a664',
  },
};

export default env;
