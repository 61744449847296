import React from 'react';
import { Route } from 'react-router-dom';
import { Protected } from '../components/commons/protected/protected';
import { NoAccessPage } from '../pages/no-access.page';
import { routes, UrlPath } from '../routes';

export type UrlList = UrlPath & {
  parents: UrlPath[];
  urlPath: string;
};

export function useRoutes() {
  const getRoute = React.useCallback((curr: UrlPath, parent?: UrlList) => {
    const li: UrlList[] = [];
    const parents: UrlPath[] = [];
    if (parent) {
      parents.push(...parent.parents);
      parents.push(parent);
    }
    const urlPath = '/' + [...parents.map((x) => x.path), curr.path].join('/');
    const l: UrlList = { ...curr, parents, urlPath };
    li.push(l);
    if (curr.sub) {
      curr.sub.forEach((p) => {
        const sl = getRoute(p, l);
        li.push(...sl);
      });
    }
    return li;
  }, []);

  const list = React.useMemo<UrlList[]>(() => {
    const li: UrlList[] = [];
    routes.forEach((p) => {
      const sl = getRoute(p);
      li.push(...sl);
    });
    return li;
  }, [getRoute]);

  const getRoutes = React.useCallback(() => {
    const r: JSX.Element[] = [];
    list.forEach((li) => {
      if (!li.element || !li.urlPath) return;
      const element = li.protected ? (
        <Protected roles={li.roles} noAccessElement={<NoAccessPage />}>
          {li.element}
        </Protected>
      ) : (
        li.element
      );
      r.push(<Route key={li.urlPath} path={li.urlPath} element={element} />);
    });
    return r;
  }, [list]);

  return {
    list,
    routes: getRoutes(),
  };
}
