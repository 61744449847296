import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import log from 'loglevel';
import React from 'react';
import { DataTableStandalone } from '../../components/commons/data-table/data-table-standalone';
import { useFormController } from '../../components/commons/form/controller/form-controller';
import { FileDropzoneValue } from '../../components/commons/form/file-upload/file-dropzone';
import { useColumns } from './aggregator-import-files-status-column';

export enum ImportFileStatusEnum {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ImportFileStatusObj = {
  id: string;
  file: File;
  status: ImportFileStatusEnum;
  result?: any;
  error?: any;
}

export type AggregatorImportFilesStatusProps = {
  statuses: ImportFileStatusObj[];
  disabled?: boolean;
}

export function AggregatorImportFilesStatus(props: AggregatorImportFilesStatusProps) {
  const { disabled, statuses } = props;

  const { setValue, getValues, watch } = useFormController();
  const [files, setFiles] = React.useState<FileDropzoneValue[]>([]);
  const [result, setResult] = React.useState<ImportFileStatusObj>();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const onDelete = React.useCallback((id: string) => () => {
    const f = getValues('files') as FileDropzoneValue[];
    setValue('files', f.filter((x) => x.id !== id));
  }, [getValues, setValue]);

  const showStatus = React.useCallback((id: string) => () => {
    const selected = statuses.find((x) => x.id === id);
    setResult(selected);
    setOpen(true);
    log.debug('selected', selected);
  }, [statuses]);

  const columns = useColumns();
  const records = React.useMemo(() => {
    const fs = (getValues('files') ?? []) as FileDropzoneValue[];
    log.trace('same length', fs.length === files.length); // TODO: not synced with watch
    return fs.map((f, idx) => {
      const s = statuses.find((x) => x.id === f.id);
      let icon: JSX.Element | undefined = <></>;
      let color: string = 'default';
      switch (s?.status) {
        case ImportFileStatusEnum.SUCCESS:
          icon = <DoneIcon />;
          color = 'success'
          break;
        case ImportFileStatusEnum.ERROR:
          icon = <ErrorOutlineIcon />;
          color = 'error';
          break;
        case ImportFileStatusEnum.PENDING:
          icon = <MoreHorizIcon />;
          break;
        case ImportFileStatusEnum.UPLOADING:
          icon = <CircularProgress size={'15px'} />;
          color = 'info';
          break;
        default:
          icon = undefined;
          break;
      }

      return {
        id: f.id,
        filename: f.file.name,
        status: s?.status,
        error: s?.error,
        result: s?.result,
        file: s?.file,
        icon,
        color,
        disabled,
        showStatus: showStatus(f.id),
        deleteFile: onDelete(f.id),
      };
    });
  }, [getValues, showStatus, onDelete, statuses, disabled, files]);

  React.useEffect(() => {
    const { unsubscribe } = watch((data: any) => {
      log.debug(data);
      setFiles(data.files ?? []);
    });
    return () => unsubscribe();
  }, [watch]);

  return (
    <Box>
      {records.length === 0 ? undefined : (
        <DataTableStandalone
          data={records}
          columns={columns}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>
          {String(result?.status).toUpperCase()} :: {result?.file.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {result?.status === ImportFileStatusEnum.SUCCESS ? (
              <>{`${result?.result?.totalInsert} row(s) successfully saved;`}</>
            ) : undefined}
            {result?.status === ImportFileStatusEnum.ERROR ? (
              <Box>
                <Box sx={{ fontSize: '120%' }}><b>Error:</b></Box>
                <span>[{result?.result?.errorCode || ''}]</span>
                <span>&nbsp;</span>
                <span>{result?.result?.message || result?.error?.message || 'Unknown Error'}</span>
                <ul>
                  {(result?.result?.errors || []).map(
                    (item: any, index: number) => (
                      <li key={index}>
                        {item.message}
                        {item.count > 1 ? ` [${item.count} errors]` : ''}
                      </li>
                    )
                  )}
                </ul>
              </Box>
            ) : undefined}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {result?.status === ImportFileStatusEnum.ERROR
            && result.result?.linkAttached
            ? (
              <Button href={result.result.linkAttached}
                variant='outlined' color='error'>
                Download Errors
              </Button>
            ) : undefined}
          <Button onClick={handleClose} variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
