import { ColumnDef } from '@tanstack/react-table';
import { DisplayDate } from '../../../components/commons/data-display/DisplayDate';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'attendanceId',
      accessorKey: 'attendanceId',
      header: 'Attendance ID',
    },
    {
      id: 'shiftReportId',
      accessorKey: 'shiftReportId',
      header: 'Shift Report ID',
    },
    {
      id: 'dsrId',
      accessorKey: 'dsrId',
      header: 'DSR ID',
    },
    {
      id: 'subsidiary',
      accessorKey: 'subsidiary',
      header: 'Subsidiary',
    },
    {
      id: 'stationCode',
      accessorKey: 'stationCode',
      header: 'Station Code',
    },
    {
      id: 'stationName',
      accessorKey: 'stationName',
      header: 'Station Name',
    },
    {
      id: 'shiftCode',
      accessorKey: 'shiftCode',
      header: 'Shift Code',
    },
    {
      id: 'date',
      accessorKey: 'date',
      header: 'Date',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'dateTime',
      accessorKey: 'dateTime',
      header: 'Date/Time',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'location',
      accessorKey: 'location',
      header: 'Location',
    },
    {
      id: 'employeeName',
      accessorKey: 'employeeName',
      header: 'Employee Name',
    },
    {
      id: 'agency',
      accessorKey: 'agency',
      header: 'Agency',
    },
    {
      id: 'position',
      accessorKey: 'position',
      header: 'position',
    },
    {
      id: 'dateTimeIn',
      accessorKey: 'dateTimeIn',
      header: 'Date Time In',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'dateTimeOut',
      accessorKey: 'dateTimeOut',
      header: 'Date Time Out',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'regular_hours',
      accessorKey: 'regularHours',
      header: 'Regular Hours',
    },
    {
      id: 'overtime_hours',
      accessorKey: 'overtimeHours',
      header: 'Overtime Hours',
    },
  ];

  return columns;
}
