import moment from 'moment-timezone';
import { BasicDsrQueryApi } from '../../apis/dsr.api';

export interface FilterState extends BasicDsrQueryApi {
}

export function useInitialFilterState() {
  const now = Date.now();
  const endDate = moment(now).tz('Asia/Manila').endOf('day');
  const startDate = endDate.clone().subtract(7, 'day').startOf('day');

  const initialFilterState: FilterState = {
    searchKey: '',
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
    shiftReportId: '',
    dsrId: '',
    stationCode: '',
    subsidiary: '',
    shiftCode: '',
  };

  return initialFilterState;
}