import React from 'react';
import { Protected } from '../components/commons/protected/protected';
import Path from '../enums/path';
import { Role } from '../enums/roles';
import { useAppContext } from '../hooks/useApp';
import { useRoutes } from '../hooks/useRoutes';

export function DefaultPapge() {
  const { firebase } = useAppContext();
  const { list } = useRoutes();

  const page = React.useMemo(() => {
    const userRoles: string[] =
      ((firebase.claims?.claims || {}) as any).roles || [];
    if (!firebase.user || userRoles.length === 0) {
      return list.find((x) => x.urlPath === Path.UserProfile);
    } else if (userRoles.includes(Role.ADMIN)) {
      return list.find((x) => x.urlPath === Path.AggregatedSummary);
    } else if (userRoles.includes(Role.SH)) {
      return list.find((x) => x.urlPath === Path.DsrImport);
    } else if (userRoles.includes(Role.AR)) {
      return list.find((x) => x.urlPath === Path.SettlementList);
    } else if (userRoles.includes(Role.E2E)) {
      return list.find((x) => x.urlPath === Path.AggregatedSummary);
    }
    return list.find((x) => x.urlPath === Path.UserProfile);
  }, [list, firebase]);

  return (
    <Protected roles={page?.roles}>
      {page?.element || <>Something went wrong...</>}
    </Protected>
  );
}
