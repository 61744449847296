import React from "react";
import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";

export interface FormContext extends UseFormReturn {

};

export type FormControllerProps = {
  useFormProps?: UseFormProps;
  onChange?: (data: any) => void;
  onSubmit?: (data: any) => Promise<void>;
  children?: any;
}

let FormControllerContext: React.Context<FormContext>;

export function FormController(props: FormControllerProps) {
  const { useFormProps, onChange, onSubmit } = props;

  const data: FormContext = useForm(useFormProps);
  FormControllerContext = React.createContext(data);

  const handleSubmit = React.useCallback(async (data: any) => {
    if (onSubmit) {
      return await onSubmit(data)
    }
  }, [onSubmit]);

  React.useEffect(() => {
    const { unsubscribe } = data.watch((data: any) => {
      if (onChange) onChange(data);
    });
    return () => unsubscribe();
  }, [data, onChange]);

  return (
    <FormControllerContext.Provider value={data}>
      <form onSubmit={data.handleSubmit(handleSubmit)}>
        {props.children}
      </form>
    </FormControllerContext.Provider>
  );

}

export function useFormController() {
  const context = React.useContext(FormControllerContext);
  if (!context) {
    throw new Error('useFormControllerContext must be used inside a <FormController>');
  }
  return context;
}
