// import DsrImportModule from '../modules/dsr/dsr-import/dsr-import';
import { DefaultLayout } from '../components/layout/twentyfour/default';
import { DsrImportModule } from '../modules/dsr/dsr-import.module';

export function DsrImportPage() {
  return (
    <DefaultLayout>
      <DsrImportModule />
    </DefaultLayout>
  );
};
