import { Button, ButtonProps } from "@mui/material";
import React from "react";

export interface FileDownloadProps extends Omit<ButtonProps, 'onClick'> {
  url: string;
}

export function FileDownloadExternal(props: FileDownloadProps) {
  const {
    children,
    url,
    ...buttonProps
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(async (e) => {
    setLoading(true);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    setLoading(false);
  }, [url])

  return (
    <Button
      {...buttonProps}
      disabled={loading}
      onClick={onClick}
    >
      {children || 'Download'}
    </Button>
  )
}
