import React from 'react';

import {
  exportAggregated,
  GetAggregatedQueryApi,
} from '../../apis/aggregator.api';
import { ListApiProps } from '../../apis/api.type';
import { useDataTableContext } from '../../components/commons/data-table/data-table-control';
import { FileDownload } from '../../components/commons/file-download/file-download';

export type AggregatedListExportProps = {};
interface QueryData {
  batchId: any;
  startDate: any;
  endDate: any;
  uploadedBy: any;
  syncStatus?: any;
  filter?: any;
}

export function AggregatedListExport(props: AggregatedListExportProps) {
  const tableContext = useDataTableContext();

  const { startDate, endDate, filter } = tableContext;

  const [query, setQuery] = React.useState<
    ListApiProps<GetAggregatedQueryApi>['query']
  >({});
  const filename = 'aggregated_summary_by_status.csv';

  const onDownload = React.useCallback(async () => {
    return exportAggregated({ query });
  }, [query]);

  React.useEffect(() => {
    const queryData: QueryData = {
      batchId: filter?.batchId,
      startDate: startDate,
      endDate: endDate,
      uploadedBy: filter?.uploadedBy,
    };
    if (filter?.syncStatus) {
      queryData.syncStatus = filter?.syncStatus;
    }
    setQuery(queryData);
  }, [startDate, endDate, filter]);

  return (
    <FileDownload
      variant="contained"
      filename={filename}
      onDownload={onDownload}
    >
      Export CSV
    </FileDownload>
  );
}
