import { Box, SxProps } from '@mui/material';
import Big from 'big.js';
import React from 'react';

export type DisplayFloatProps = {
  children: string | number | Big;
  thousandsSep?: boolean;
  decimalPlaces?: number;
  isPercent?: boolean;
  sx?: SxProps;
}

export function DisplayFloat(props: DisplayFloatProps) {
  const { children, sx, decimalPlaces, thousandsSep = true, isPercent = false } = props;

  const displayValue = React.useMemo(() => {
    let number: Big;
    let numberStr: string;
    try {
      number = Big(children);
      numberStr = number.toString();
    } catch (e) {
      if (children !== null && children !== undefined) {
        console.log(`Invalid number: "${String(children)}"`);
        throw e;
      }
      return '-';
    }
    if (decimalPlaces !== undefined) numberStr = number.toFixed(decimalPlaces);
    if (thousandsSep) {
      const parts = numberStr.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      numberStr = parts.join('.');
    }
    if (isPercent) {
      numberStr = number.mul(100).toString();
      if (decimalPlaces !== undefined) numberStr = number.mul(100).toFixed(decimalPlaces);
      numberStr += '%';
    }
    return numberStr;
  }, [children, decimalPlaces, thousandsSep, isPercent]);

  return (
    <Box sx={{ textAlign: 'right', ...sx }}>
      {displayValue}
    </Box>
  )
}