import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { FilterObject } from '../../apis/api.type';
import { useDataTableContext } from '../../components/commons/data-table/data-table-control';
import { AggregatedSyncStatusEnum } from '../../enums/sync-status';

export function AggregatedListFilter() {
  const context = useDataTableContext();

  const [open, setOpen] = React.useState(false);
  const [customFilter, setCustomFilter] = React.useState<FilterObject>({
    ...context.filter,
  });

  const handleSave = () => {
    const f: any = {};
    const keys = ['batchId', 'searchKey', 'uploadedBy', 'syncStatus'];
    keys.forEach((key) => {
      f[key] = customFilter[key] ? customFilter[key] : undefined;
    });
    context.setFilter(f);
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const key = e.currentTarget.name;
    const value = e.currentTarget.value;
    setCustomFilter((curr) => {
      const { [key]: oldValue, ...newObject } = curr;
      if (value === undefined || value === '') return newObject;
      return { ...newObject, [key]: value };
    });
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    const key = e.target.name;
    const value = e.target.value;
    setCustomFilter((curr) => {
      const { [key]: oldValue, ...newObject } = curr;
      if (value === undefined || value === '' || value === '?') return newObject;
      return { ...newObject, [key]: value };
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCustomFilter(context.filter || {});
  };

  /** FOr badge number */
  const count = React.useMemo(() => {
    const f: any = { ...customFilter };
    const keys = ['batchId', 'searchKey', 'uploadedBy', 'syncStatus'];
    let c = 0;
    keys.forEach((key) => {
      if (f[key] !== null && f[key] !== undefined && f[key] !== '') {
        c += 1;
      }
    });
    return c;
  }, [customFilter])

  return (
    <>
      <Badge color="success" overlap="circular" badgeContent={count}>
        <IconButton
          onClick={handleOpen}
        ><FilterAltIcon /></IconButton>
      </Badge>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth='md'
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: '10px' }}>
            <Stack spacing={2}>
              <TextField
                disabled
                id="batchId"
                name="batchId"
                label="Batch ID"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                value={customFilter.batchId}
              />
              <TextField
                id="uploadedBy"
                name="uploadedBy"
                label="Uploaded By"
                type="email"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                value={customFilter.uploadedBy || ''}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sync Status</InputLabel>
                <Select
                  labelId="syncStatus"
                  id="syncStatus"
                  name="syncStatus"
                  value={customFilter.syncStatus || '?'}
                  label="Sync Status"
                  onChange={handleSelectChange}
                >
                  <MenuItem key='none' value='?'>** none selected **</MenuItem>
                  {Object.values(AggregatedSyncStatusEnum).map((v) => {
                    return <MenuItem key={v} value={v}>{v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
