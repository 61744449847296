const ApiPath = {
  Settlement: 'ms-aggregator/settlement',
  Aggregator: 'ms-aggregator/aggregator',
  AggregatorAggregated: 'ms-aggregator/aggregator/aggregated',
  AggregatorAggregatedExport: 'ms-aggregator/aggregator/aggregated/export',
  AggregatorAggregatedSummaryByStatus:
    'ms-aggregator/aggregator/aggregated-summary-by-status',
  AggregatorAggregatedSummaryByStatusExport:
    'ms-aggregator/aggregator/aggregated-summary-by-status/export',
  AggregatorTransactionByCreatedAtUploadedByStatus:
    'ms-aggregator/aggregator/transaction-by-created-at-uploaded-by-status',
  AggregatorTransactionWithStatus:
    'ms-aggregator/aggregator/transaction-with-sync-status',
  Dsr: 'ms-aggregator/dsr',
  DsrTransaction: 'ms-aggregator/dsr/transaction',
  DsrShift: 'ms-aggregator/dsr/shift',
  DsrShiftSummary: 'ms-aggregator/dsr/shift/summary',
  // DsrShiftByStationCodeDate: 'ms-aggregator/dsr/shift/by-station-code-date',
  DsrPump: 'ms-aggregator/dsr/pump',
  DsrItemReceipt: 'ms-aggregator/dsr/item-receipt',
  DsrInventory: 'ms-aggregator/dsr/inventory',
  DsrExpense: 'ms-aggregator/dsr/expense',
  DsrDeposit: 'ms-aggregator/dsr/deposit',
  DsrCollection: 'ms-aggregator/dsr/collection',
  DsrCashShort: 'ms-aggregator/dsr/cash-short',
  DsrCashOver: 'ms-aggregator/dsr/cash-over',
  DsrCashBreakdown: 'ms-aggregator/dsr/cash-breakdown',
  DsrAttendance: 'ms-aggregator/dsr/attendance',

  DsrTransactionExport: 'ms-aggregator/dsr/transaction/export',
  DsrShiftExport: 'ms-aggregator/dsr/shift/export',
  DsrPumpExport: 'ms-aggregator/dsr/pump/export',
  DsrItemReceiptExport: 'ms-aggregator/dsr/item-receipt/export',
  DsrInventoryExport: 'ms-aggregator/dsr/inventory/export',
  DsrExpenseExport: 'ms-aggregator/dsr/expense/export',
  DsrDepositExport: 'ms-aggregator/dsr/deposit/export',
  DsrCollectionExport: 'ms-aggregator/dsr/collection/export',
  DsrCashShortExport: 'ms-aggregator/dsr/cash-short/export',
  DsrCashOverExport: 'ms-aggregator/dsr/cash-over/export',
  DsrCashBreakdownExport: 'ms-aggregator/dsr/cash-breakdown/export',
  DsrAttendanceExport: 'ms-aggregator/dsr/attendance/export',

  Customer: 'ms-aggregator/customer',
  Location: 'ms-aggregator/location',
};

export default ApiPath;
