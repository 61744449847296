import { Box, Chip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment-timezone';
import { GetDsrShiftSummaryListRecord } from '../../../apis/dsr.api';
import { DsrSummaryDialogModule } from './summary-dialog.module';

export function useColumns(selectedSationCode: string) {
  const shiftCell = (info: any) => (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center', // Centers text alignment
        display: 'flex',
        justifyContent: 'center', // Centers content horizontally within the box
      }}
    >
      <Chip
        label={info.getValue()}
        color={info.getValue() === 0 ? 'error' : 'success'}
        size="small"
        sx={{ width: '42px' }}
      />
    </Box>
  );

  const centeredHeader = (header: string) => () =>
    <Box sx={{ textAlign: 'center' }}>{header}</Box>;

  const columns: ColumnDef<GetDsrShiftSummaryListRecord>[] = [
    {
      accessorKey: 'businessDate',
      header: 'Shift Date',
      cell: (info) => {
        const date = moment(info.getValue() as string).tz('Asia/Manila');
        return (
          <DsrSummaryDialogModule
            label={date.format('MMMM D, YYYY')}
            stationCode={selectedSationCode}
            startDate={info.getValue() as string}
            endDate={info.getValue() as string}
          />
        );
      },
    },
    {
      accessorKey: 'shift1',
      header: centeredHeader('Shift 1'),
      cell: shiftCell,
    },
    {
      accessorKey: 'shift2',
      header: centeredHeader('Shift 2'),
      cell: shiftCell,
    },
    {
      accessorKey: 'shift3',
      header: centeredHeader('Shift 3'),
      cell: shiftCell,
    },
  ];

  return columns;
}
