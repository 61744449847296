import Box from "@mui/material/Box";
import { FormController } from "../../components/commons/form/controller/form-controller";
import Intro from "../../components/commons/intro/intro";
import { DsrSummaryFilter } from "./dsr-summary-filter";
import { useInitialFilterState } from "./dsr-summary-filter.state";
import { DsrSummaryListModule } from "./summary/summary-list.module";

export function DsrSummaryModule() {
  const initialState = useInitialFilterState();
  return (
    <>
      <Intro title="DSR Summary" subtitle="Overview of all DSRs uploaded" />
      <FormController
        useFormProps={{
          defaultValues: initialState
        }}
      >
        <Box sx={{ mb: '20px' }}>
          <DsrSummaryFilter />
        </Box>
        <Box>
          <DsrSummaryListModule />
        </Box>
      </FormController>
    </>
  );
}
