import { ColumnDef, Table } from '@tanstack/react-table';
import { FilterObject, ListApiFn, ListApiProps } from 'apis/api.type';
import { useAppContext } from 'hooks/useApp';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface DataTableControlProps<Record = any> {
  children?: any;
  listFn: ListApiFn;
  initialApiProps?: ListApiProps;
  columns: ColumnDef<Record>[];
  autoCommit?: boolean;
  filter?: FilterObject;
}

type ApiResponse = Awaited<ReturnType<ListApiFn>>;

export type DataTable = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<DataTable['page']>>;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<DataTable['perPage']>>;
  searchKey?: string;
  setSearchKey: React.Dispatch<React.SetStateAction<DataTable['searchKey']>>;
  orderBy?: string;
  setOrderBy: React.Dispatch<React.SetStateAction<DataTable['orderBy']>>;
  order?: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<DataTable['order']>>;
  startDate?: string;
  setStartDate: React.Dispatch<React.SetStateAction<DataTable['startDate']>>;
  endDate?: string;
  setEndDate: React.Dispatch<React.SetStateAction<DataTable['endDate']>>;
  filter?: FilterObject;
  setFilter: React.Dispatch<React.SetStateAction<DataTable['filter']>>;
  //
  apiCall: (
    modifyRequestFn: (request: ListApiProps) => Promise<ListApiProps>
  ) => ReturnType<ListApiFn>;
  loading: boolean;
  response?: ApiResponse['data'];
  data?: ApiResponse['data']['data']['records'];
  columns: DataTableControlProps['columns'];
  commit: () => void;
  uncommited: number;
  tableRef: React.MutableRefObject<Table<any> | undefined>;
};

let DataTableContext: React.Context<DataTable>;

export function DataTableControl(props: DataTableControlProps) {
  const { api: apiContext, errorHandler } = useAppContext();
  const { setError } = errorHandler;

  const {
    initialApiProps: initApi = {},
    listFn,
    columns,
    autoCommit = false,
    filter: initFilter,
  } = props;

  const [commitId, setCommitId] = React.useState(uuidv4());
  const [page, setPage] = React.useState(initApi.query?.page || 1);
  const [perPage, setPerPage] = React.useState(initApi.query?.perPage || 10);
  const [searchKey, setSearchKey] = React.useState(initApi.query?.searchKey);
  const [orderBy, setOrderBy] = React.useState(initApi.query?.orderBy);
  const [order, setOrder] = React.useState(initApi.query?.order);
  const [startDate, setStartDate] = React.useState(initApi.query?.startDate);
  const [endDate, setEndDate] = React.useState(initApi.query?.endDate);
  const [filter, setFilter] = React.useState(initApi.query?.filter);
  const [uncommited, setUncommited] = React.useState(0);
  const tableRef = React.useRef<Table<any>>();

  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<ApiResponse>();
  const [lastRequest, setLastRequest] = React.useState(
    autoCommit ? '' : commitId
  );

  /**
   *
   */
  const apiRequest = React.useMemo(() => {
    setUncommited((n) => n + 1);
    return {
      ...initApi,
      $id: autoCommit ? uuidv4() : commitId, // for tracking only
      query: {
        page: !page || page < 1 ? 1 : page, // minimum
        perPage: !perPage || perPage < 10 ? 10 : perPage, // minimum
        searchKey,
        orderBy,
        order,
        startDate,
        endDate,
        filter: filter ? JSON.stringify(filter) : undefined, // @deprecated
        ...initFilter,
        ...filter,
      },
    };
  }, [
    page,
    perPage,
    searchKey,
    orderBy,
    order,
    startDate,
    endDate,
    filter,
    initApi,
    autoCommit,
    commitId,
    initFilter,
  ]);

  /**
   * Changes commit id
   */
  const commit = React.useCallback((id?: string) => {
    const newId = id || uuidv4();
    setCommitId(newId);
    return newId;
  }, []);

  /**
   *
   */
  const apiCall = React.useCallback(
    async (
      modifyRequestFn?: (request: ListApiProps) => Promise<ListApiProps>
    ) => {
      const { $id, ...currRequest } = apiRequest;
      let newRequest = { ...currRequest } as ListApiProps;
      if (modifyRequestFn) {
        newRequest = await modifyRequestFn(newRequest);
      }
      return listFn(newRequest as ListApiProps);
    },
    [apiRequest, listFn]
  );

  const data: DataTable = {
    page,
    setPage,
    perPage,
    setPerPage,
    searchKey,
    setSearchKey,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filter,
    setFilter,
    //
    apiCall,
    loading,
    response: response?.data,
    data: response?.data.data.records,
    columns,
    commit,
    uncommited,
    tableRef,
  };

  React.useEffect(() => {
    // do not run before initializing
    if (!apiContext.initialized) return;
    // do not run if request is the same as last
    const { $id } = apiRequest;
    if ($id === lastRequest) return;
    setLastRequest($id);
    // do not run if still loading
    if (loading) return;
    setLoading(true);
    //
    apiCall()
      .then((r) => {
        setResponse(r);
        setUncommited(0);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  }, [
    apiContext.initialized,
    lastRequest,
    apiCall,
    apiRequest,
    loading,
    uncommited,
    setError,
  ]);

  DataTableContext = React.createContext(data);

  return (
    <DataTableContext.Provider value={data}>
      {props.children}
    </DataTableContext.Provider>
  );
}

export function useDataTableContext() {
  const context = React.useContext(DataTableContext);
  if (!context) {
    throw new Error('useDataTableContext must be inside the DataTableControl');
  }
  return context;
}
