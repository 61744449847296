import { Button, Stack } from '@mui/material';
import Intro from 'components/commons/intro/intro';
import log from 'loglevel';
import React from 'react';
import { importAggregator } from '../../apis/aggregator.api';
import { BackLink } from '../../components/commons/back-link/back-link';
import { FormController } from '../../components/commons/form/controller/form-controller';
import { FileDropzone, FileDropzoneValue } from '../../components/commons/form/file-upload/file-dropzone';
import Path from '../../enums/path';
import { AggregatorImportFilesStatus, ImportFileStatusEnum, ImportFileStatusObj } from './aggregator-import-files-status';

export function AggregatorImportFilesModule() {
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState<ImportFileStatusObj[]>([]);

  // const handleChange = React.useCallback((d: any) => {
  //   if (Array.isArray(d.files)) {
  //     setLoaded(d.files.length > 0);
  //   }
  // }, []);

  const updateStatus = React.useCallback((
    id: string,
    status: ImportFileStatusEnum,
    result?: Partial<ImportFileStatusObj>,
  ) => {
    setResults((curr) => curr.map((x) => {
      if (x.id === id) {
        log.debug('upload result', { ...x, ...result, status });
        return { ...x, ...result, status };
      }
      return x;
    }));
  }, []);

  const submitOneFile = React.useCallback(async (file: FileDropzoneValue) => {
    updateStatus(file.id, ImportFileStatusEnum.UPLOADING);
    await importAggregator({ data: file.file })
      .then((result) => {
        const totalError = result?.totalError ?? 0;
        const hasError = totalError > 0;
        let r = result;
        const errors: any[] = [];
        (result?.errorList || []).forEach((item: any) => {
          const m = typeof item === 'string' ? item : item.message;
          const ex = errors.find((x: any) => x.message === m);
          if (!ex) {
            errors.push(typeof item === 'string' ? {
              message: m,
              count: 1,
            } : item);
          } else {
            ex.count += 1;
          }
        });
        if (hasError) {
          if (result?.errorList && Array.isArray(result?.errorList)) {
            r = result.errorList[0];
          }
          if (totalError > 1) {
            r = {
              errorCode: 'MULTIPLE_ERRORS',
              message: `${totalError} errors received`,
              errors,
            };
          }
        }
        r.linkAttached = result?.linkAttached;
        updateStatus(
          file.id,
          hasError ? ImportFileStatusEnum.ERROR : ImportFileStatusEnum.SUCCESS,
          {
            result: r,
            error: undefined,
          },
        );
      })
      .catch((err) => {
        updateStatus(file.id, ImportFileStatusEnum.ERROR, {
          result: err?.response?.data,
          error: err,
        });
      });
  }, [updateStatus]);

  const onSubmit = React.useCallback(async (d: any) => {
    if (loading) return;
    setLoading(true);
    const files = (d.files ?? []) as FileDropzoneValue[];
    log.debug('submitted files', files);
    setResults(files.map((x) => ({
      id: x.id,
      file: x.file,
      status: ImportFileStatusEnum.PENDING,
    })));
    for (const i in files) {
      await submitOneFile(files[i]);
    }
    setLoading(false);
  }, [loading, submitOneFile]);

  return (
    <>
      <BackLink list={[{ text: 'Summary', path: Path.AggregatedSummary }]} />
      <Intro title="Import Multiple Files" subtitle="Import Multiple Validated DSR Sales" />
      <FormController onSubmit={onSubmit}>
        <Stack direction='column' spacing={2}>
          <FileDropzone
            name='files'
            multiple
            hideFiles
            dropzoneOptions={{
              disabled: loading,
              accept: {
                'text/plain': ['.csv'],
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.csv'],
              },
            }}
          />
          <AggregatorImportFilesStatus
            statuses={results}
            disabled={loading}
          />
          <Button type='submit' disabled={loading}>Submit</Button>
        </Stack>
      </FormController>
    </>
  );
}
