import axios from "axios";
import ApiPath from "../enums/api-path";
import { ListApiFn, ListApiProps } from "./api.type";

export type CustomerResponse = {
  customerId: number,
  name: string,
  displayName: string,
  allowCredit: boolean,
}

export const getCustomers: ListApiFn<CustomerResponse> = async (props: ListApiProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.Customer}`, { params: query });
  return res;
};
