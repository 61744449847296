import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import React from 'react';
// import PageTitleLine from 'components/page-title-line/page-title-line';
import { FileUpload } from 'components/commons/file-upload/file-upload';
import Path from 'enums/path';
import { PaymentProcessorEnum } from 'enums/payment-processor';
import { Controller, useForm } from 'react-hook-form';
// import { prettifyPaymentProcessor } from 'utils/pretty.utils';
import { ImportSettlementsProps, importSettlement } from 'apis/settlement.api';
import { BackLink } from 'components/commons/back-link/back-link';
import { Intro } from 'components/commons/intro/intro';
import { useSwal } from 'hooks/useSwal';

export function SettlementImportModule() {
  const swal = useSwal();

  const [loading, setLoading] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<ImportSettlementsProps>();

  const onSubmit = async (data: any) => {
    const { isConfirmed } = await swal.showConfirm({
      title: 'Confirmation',
      text: 'Are you sure you want to submit?. Please click Confirm button to submit your request.',
    });

    if (!isConfirmed) {
      return false;
    }

    try {
      setLoading(true);
      const res = await importSettlement(data).finally(() => setLoading(false));
      swal.showSuccess({
        title: 'Success!',
        text: res?.message,
        preConfirm: () => {
          return reset();
        },
      });
    } catch (e: any) {
      setLoading(false);
      swal.showError({
        title: 'Error!',
        text: e?.response?.data?.message || e.message,
        preConfirm: () => {
          return onSubmit(data);
        },
      });
    }
  };

  return (
    <div>
      <BackLink list={[{ text: 'List', path: Path.Settlement }]} />
      <Intro
        title="Non-cash settlement reports upload page"
        subtitle="Upload settlement reports from non-cash settlement partners"
      />
      <Card variant="outlined" component={'div'}>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Controller
                name="processor"
                control={control}
                rules={{ required: 'Processor field is required' }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field} sx={{ minWidth: '200px' }}
                    error={Boolean(errors?.processor?.message)}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={PaymentProcessorEnum.GCASH}>
                      {PaymentProcessorEnum.GCASH}
                    </MenuItem>
                    <MenuItem value={PaymentProcessorEnum.MAYA}>
                      {PaymentProcessorEnum.MAYA}
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={true}>
                <>{errors?.processor?.message}</>
              </FormHelperText>
              <Controller
                name="data"
                control={control}
                rules={{ required: 'Select File is required' }}
                render={({ field }) => (
                  <FileUpload
                    multiple
                    buttonText="Select Files"
                    noDrag={true}
                    buttonProps={{
                      variant: 'outlined',
                      color: 'primary',
                    }}
                    name={field.name}
                    value={field.value || []}
                    onChange={(e) => field.onChange(e)}
                    sx={{ textAlign: 'center' }}
                    accept={{
                      'application/vnd.ms-excel': ['.xls'],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        ['.xlx'],
                      'text/csv': ['.csv'],
                    }}
                    error={Boolean(errors?.data?.message)}
                    helperText={errors?.data?.message}
                  />
                )}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
