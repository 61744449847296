import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Grid, Stack } from '@mui/material';
import { getSettlements } from 'apis/settlement.api';
import { DataTable } from 'components/commons/data-table/data-table';
import { DataTableControl } from 'components/commons/data-table/data-table-control';
import { DataTablePagination } from 'components/commons/data-table/data-table-pagination';
import { DataTableSearchBox } from 'components/commons/data-table/data-table-search-box';
import Intro from 'components/commons/intro/intro';
import Path from 'enums/path';
import { useNavigate } from 'react-router-dom';
import { columns } from './settlement-list-column';
import { initialFilterState } from './settlement-list-filter.state';

export type SettlementListProps = {};

export function SettlementListModule(props: SettlementListProps) {
  const navigate = useNavigate();

  return (
    <>
      <Intro
        title="Non-cash settlement reports"
        subtitle="Consolidate settlement reports from non-cash settlement channels"
      />
      <DataTableControl
        columns={columns}
        listFn={getSettlements}
        initialApiProps={initialFilterState}
        autoCommit
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <DataTableSearchBox />
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end">
                <Button
                  variant="outlined"
                  startIcon={<FileUploadIcon />}
                  onClick={() => navigate(Path.SettlementImport)}
                >
                  Import
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <DataTable />

          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
