import React from 'react';

import {
  exportAggregatedSummaryByStatus,
  GetAggregatedSummaryByStatusQueryApi,
} from '../../apis/aggregator.api';
import { ListApiProps } from '../../apis/api.type';
import { useDataTableContext } from '../../components/commons/data-table/data-table-control';
import { FileDownload } from '../../components/commons/file-download/file-download';

export type AggregatedSummaryExportProps = {};
interface QueryData {
  searchKey: any;
  startDate: any;
  endDate: any;
  uploadedBy: any;
  syncStatus?: any;
  filter?: any;
}

export function AggregatedSummaryExport(props: AggregatedSummaryExportProps) {
  const tableContext = useDataTableContext();

  const { searchKey, startDate, endDate, filter } = tableContext;

  const [query, setQuery] = React.useState<
    ListApiProps<GetAggregatedSummaryByStatusQueryApi>['query']
  >({});
  const filename = 'aggregated_summary_by_status.csv';

  const onDownload = React.useCallback(async () => {
    return exportAggregatedSummaryByStatus({ query });
  }, [query]);

  React.useEffect(() => {
    const queryData: QueryData = {
      searchKey: searchKey,
      startDate: startDate,
      endDate: endDate,
      uploadedBy: filter?.uploadedBy,
    };

    if (filter?.syncStatus) {
      queryData.syncStatus = filter?.syncStatus;
    }
    setQuery(queryData);
  }, [searchKey, startDate, endDate, filter]);

  return (
    <FileDownload
      variant="contained"
      filename={filename}
      onDownload={onDownload}
    >
      Export CSV
    </FileDownload>
  );
}
