import { Stack } from '@mui/material';

import moment from 'moment-timezone';
import React from 'react';
import {
  // getDSRShiftByStationCodeDate,
  getDsrShiftSummaryList,
  GetDsrShiftSummaryListQueryApi,
  GetDsrShiftSummaryListRecord,
} from '../../../apis/dsr.api';
import { DataTableStandalone } from '../../../components/commons/data-table/data-table-standalone';
import { useFormController } from '../../../components/commons/form/controller/form-controller';
import { useColumns } from './summary-list-column';

export type ShiftistProps = {};

export function DsrSummaryListModule(props: ShiftistProps) {
  const formController = useFormController();

  const [filter, setFilter] = React.useState<GetDsrShiftSummaryListQueryApi>(
    {}
  );
  const [data, setData] = React.useState<GetDsrShiftSummaryListRecord[]>([]);
  const [calendar, setCalendar] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const columns = useColumns(filter?.stationCode as string);

  const records = React.useMemo<GetDsrShiftSummaryListRecord[]>(() => {
    if (!filter.stationCode) return [];
    const list: GetDsrShiftSummaryListRecord[] = calendar.map((d) => {
      const existing = data.find((x) => x.businessDate === d);
      if (existing) return existing;
      return {
        businessDate: d,
        shift1: 0,
        shift2: 0,
        shift3: 0,
      };
    });
    return list;
  }, [calendar, data, filter.stationCode]);

  const onChange = React.useCallback((data: any) => {
    setFilter({
      stationCode: data.location?.stationCode,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }, []);

  const getData = React.useCallback(async () => {
    if (!filter.stationCode || !filter.startDate || !filter.endDate) return;
    setLoading(true);
    const res = await getDsrShiftSummaryList({
      query: {
        stationCode: filter.stationCode,
        startDate: filter.startDate,
        endDate: filter.endDate,
      },
    }).finally(() => setLoading(false));
    const records = res.data?.data?.records;
    setData(records);
  }, [filter.stationCode, filter.startDate, filter.endDate]);

  /**
   * Watch form
   */
  React.useEffect(() => {
    onChange(formController.getValues());
    const { unsubscribe } = formController.watch(onChange);
    return () => unsubscribe();
  }, [formController, onChange]);

  /**
   *
   */
  React.useEffect(() => {
    getData();
  }, [getData]);

  /**
   * Generate calendar days from start to end
   * max 31 days.
   */
  React.useEffect(() => {
    const list: string[] = [];
    const now = moment().tz('Asia/Manila');
    const s = moment(filter.startDate).tz('Asia/Manila');
    const e = moment(filter.endDate).tz('Asia/Manila');
    if (!s.isValid() || !e.isValid()) return;
    const curr = s.clone();
    let done = false;
    while (!done && list.length <= 31) {
      if (curr.diff(now) <= 0) {
        list.push(curr.format('YYYY-MM-DD'));
      }
      curr.add(1, 'day');
      done = curr.diff(e) > 0 || curr.diff(now) > 0;
    }
    setCalendar(list);
  }, [filter.startDate, filter.endDate]);

  return (
    <>
      <Stack spacing={2}>
        <DataTableStandalone
          columns={columns}
          data={records}
          perPage={30}
          loading={loading}
          tableCellProps={{ sx: { padding: '2px' } }}
        />
      </Stack>
    </>
  );
}
