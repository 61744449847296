import { DefaultLayout } from "../components/layout/twentyfour/default";
import { AggregatedListModule } from "../modules/aggregator/aggregated-list.module";

export function AggregatedListPage() {
  return (
    <DefaultLayout>
      <AggregatedListModule />
    </DefaultLayout>
  );
};
