import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'createdAt',
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'cashOverId',
      accessorKey: "cashOverId",
      header: "Cash Over ID",
    },
    {
      id: 'shiftReportId',
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: 'dsrId',
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: 'subsidiary',
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: 'stationCode',
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: 'stationName',
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: 'shiftCode',
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: 'csoId',
      accessorKey: "csoId",
      header: "CSO ID",
    },
    {
      id: 'date',
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'dateTime',
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'location',
      accessorKey: "location",
      header: "Location",
    },
    {
      id: 'employeeName',
      accessorKey: "employeeName",
      header: "Employee Name",
    },
    {
      id: 'refName',
      accessorKey: "refName",
      header: "Ref Name",
    },
    {
      id: 'agency',
      accessorKey: "agency",
      header: "Agency",
    },
    {
      id: 'amount',
      accessorKey: "amount",
      header: "Amount",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'accountCode',
      accessorKey: "accountCode",
      header: "Account Code",
    },
    {
      id: 'csoType',
      accessorKey: "csoType",
      header: "CSO Type",
    },
    {
      id: 'syncStatus',
      accessorKey: "syncStatus",
      header: "Sync Status",
    },
    {
      id: 'syncedAt',
      accessorKey: "syncedAt",
      header: "Synced At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'errorMessage',
      accessorKey: "errorMessage",
      header: "Error Message",
    },
  ];

  return columns;
}
