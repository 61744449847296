import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Card, CardContent, FormControl, Grid, Stack } from '@mui/material';
import { importDsr } from 'apis/dsr.api';
import CustomDialog from 'components/commons/dialog/custom-dialog';
import { FileUpload } from 'components/commons/file-upload/file-upload';
import Intro from 'components/commons/intro/intro';
import DsrErrors from 'components/dsr-errors/dsr-errors';
import DsrReceipt from 'components/dsr-receipt/dsr-receipt';
import useDialog from 'hooks/useDialog';
import { useSwal } from 'hooks/useSwal';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FileDownloadExternal } from '../../components/commons/file-download/file-download-external';

export function DsrImportModule() {
  const swal = useSwal();
  const successDialog = useDialog();

  const [isloading, setIsLoading] = React.useState(false);
  const [errorList, setErrorList] = React.useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    setErrorList(null);
    try {
      setIsLoading(true);
      const res = await importDsr({ data: data.file[0] }).finally(() =>
        setIsLoading(false)
      );

      successDialog.show(
        'Success!',
        'Successfully uploaded with the following receipt:',
        <DsrReceipt data={res?.data?.receipt} />
      );

      setErrorList(null);
      return reset();
    } catch (e: any) {
      setIsLoading(false);
      if (e?.response?.data?.message) {
        swal.showError({
          title: 'Error!',
          text: e?.response?.data?.message,
          preConfirm: () => {
            return onSubmit(data);
          },
        });
      }
      if (e?.response?.data?.errors) {
        setErrorList(e?.response?.data.errors);
      }
    }
  };

  return (
    <>
      <Intro title="Upload Station DSR" subtitle="Upload Form" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction='row' justifyContent='right'>
            <FileDownloadExternal
              url='https://drive.google.com/uc?export=download&id=1Pa76X6Cvhhlz48l4lQVV2T3Fi75jcBTA'
              variant='outlined'
              startIcon={<DownloadIcon />}
            >Latest Template
            </FileDownloadExternal>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" component={'div'}>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <FormControl>
                    <Controller
                      name="file"
                      control={control}
                      rules={{ required: 'Select File is required' }}
                      render={({ field }) => (
                        <FileUpload
                          multiple={false}
                          buttonText="Select File"
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                            setErrorList(null);
                          }}
                          noDrag={true}
                          buttonProps={{
                            variant: 'outlined',
                            color: 'primary',
                          }}
                          sx={{ textAlign: 'center' }}
                          accept={{
                            'application/vnd.ms-excel': ['.xls'],
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                              ['.xlx'],
                            'text/csv': ['.csv'],
                          }}
                          error={Boolean(errors?.file?.message)}
                          helperText={errors?.file?.message}
                        />
                      )}
                    />
                  </FormControl>
                  {errorList && (
                    <div>
                      <h3 className="error">Found the following errors</h3>
                      <DsrErrors errors={errorList} />
                    </div>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isloading}
                  >
                    {isloading ? 'Submitting...' : 'Submit'}
                  </Button>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CustomDialog
        open={successDialog.dialog.open}
        onClose={successDialog.close}
        icon={<CheckIcon color="success" fontSize="large" />}
        title={successDialog.dialog.title}
        message={successDialog.dialog.message}
        onPrimaryButtonClick={successDialog.close}
        primaryButtonText="Ok"
      >
        {successDialog.dialog.htmlContent}
      </CustomDialog>
    </>
  );
}
