import axios from 'axios';
import ApiPath from '../enums/api-path';
import { BasicQueryApi, ListApiFn, ListApiProps } from './api.type';

export type ImportAggregatorProps = {
  data: File;
  errorReason?: string;
};

export interface GetAggregatedQueryApi extends BasicQueryApi {
  postingDate?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
  batchId?: string;
}
export const getAggregated: ListApiFn = async (
  props: ListApiProps<GetAggregatedQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorAggregated}`, {
    params: query,
  });
  return res;
};

export const exportAggregated: ListApiFn = async (
  props: ListApiProps<GetAggregatedQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorAggregatedExport}`, {
    params: query,
    responseType: 'blob',
  });
  return res;
};

export interface GetAggregatedSummaryByStatusQueryApi extends BasicQueryApi {
  postingDate?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
}
export const getAggregatedSummaryByStatus: ListApiFn = async (
  props: ListApiProps<GetAggregatedSummaryByStatusQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorAggregatedSummaryByStatus}`,
    { params: query }
  );
  return res;
};

export const exportAggregatedSummaryByStatus: ListApiFn = async (
  props: ListApiProps<GetAggregatedSummaryByStatusQueryApi>
) => {
  let { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorAggregatedSummaryByStatusExport}`,
    {
      params: query,
      responseType: 'blob',
    }
  );
  return res;
};

export const getTransactionByCreatedAtUploadedByStatus: ListApiFn = async (
  props: ListApiProps
) => {
  const { query } = props;
  const res = await axios.get(
    `${ApiPath.AggregatorTransactionByCreatedAtUploadedByStatus}`,
    { params: query }
  );
  return res;
};

export interface GetTransactionWithSyncStatusQueryApi extends BasicQueryApi {
  batchId?: string;
  stationCode?: string;
  syncStatus?: string;
  uploadedBy?: string;
}
export const getTransactionWithSyncStatus: ListApiFn = async (
  props: ListApiProps<GetTransactionWithSyncStatusQueryApi>
) => {
  const { query } = props;
  const res = await axios.get(`${ApiPath.AggregatorTransactionWithStatus}`, {
    params: {
      query: { ...query, filter: undefined },
    },
  });
  return res;
};

export const importAggregator = async (props: ImportAggregatorProps) => {
  const { data, errorReason } = props;
  const formData = new FormData();
  formData.append('data', data);
  if (errorReason) {
    formData.append('errorReason', errorReason);
  }
  return await axios
    .post(`${ApiPath.Aggregator}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
    .then((r) => r.data.data);
};
