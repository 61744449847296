import { AccountCircle } from '@mui/icons-material';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import Image from 'components/commons/image/image';
import { useAppContext } from 'hooks/useApp';
import { HeaderLogo } from 'images';

export function LoginModule() {
  const { env, firebase } = useAppContext();

  return (
    <Card elevation={5}>
      <CardContent>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Image src={HeaderLogo} width="150px" />
          <Typography variant="h4">
            {' '}
            {`${env?.label} Aggregator`.trim()}
          </Typography>
          <Typography variant="body1">Please log in to get started</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AccountCircle />}
            onClick={async () => {
              const res = await firebase.loginHandler();
            }}
            size="large"
          >
            Login with Google
          </Button>
          <Typography variant="body1" color={'error'}>
            {firebase.loginError}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
