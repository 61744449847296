import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Chip, IconButton, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useDataTableContext } from './data-table-control';

export interface DataTablePaginationProps {
}

export function DataTablePagination(props: DataTablePaginationProps) {
  const context = useDataTableContext();

  const { page, setPage, perPage, setPerPage, commit } = context;

  const belowPageCount = React.useMemo(() => (context.data?.length || 0) < perPage, [perPage, context.data]);

  const handlePreviousPage = React.useCallback(() => {
    setPage((x) => x > 1 ? x - 1 : 1);
    commit();
  }, [setPage, commit]);

  const handleNextPage = React.useCallback(() => {
    setPage((x) => belowPageCount ? x : x + 1);
    commit();
  }, [setPage, belowPageCount, commit]);

  const menuItems = [10, 20, 30, 40, 50].map((n) => {
    return <MenuItem key={n} value={n}>{`${n} / page`}</MenuItem>
  });

  const onChangePerPage = React.useCallback((n: SelectChangeEvent<number>) => {
    setPerPage(Number(n.target.value));
    commit();
  }, [setPerPage, commit]);

  return (
    <Box>
      <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
        {/* <ButtonGroup variant="outlined"> */}
        <IconButton
          onClick={handlePreviousPage}
          disabled={page === 1}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Chip variant="outlined" label={page} />
        <IconButton
          onClick={handleNextPage}
          disabled={belowPageCount}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        {/* </ButtonGroup> */}
        <Select
          size='small'
          value={context.perPage}
          onChange={onChangePerPage}
        >
          {menuItems}
        </Select>
      </Stack>
    </Box>
  );
}
