import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment-timezone';
import React from 'react';
import { LocationAutocomplete } from '../../components/commons/form/autocomplete/location-autocomplete';
import { useFormController } from '../../components/commons/form/controller/form-controller';
import { Select } from '../../components/commons/form/select/select';
import { months } from '../../enums/calendar';

interface DsrSummaryFilterProps {
}

export function DsrSummaryFilter(props: DsrSummaryFilterProps) {
  const formController = useFormController();

  const years = React.useMemo(() => {
    const y = [];
    let i = 2024; // start of implementation
    const yearEnd = moment(Date.now()).tz('Asia/Manila').get('year');
    while (i <= yearEnd) {
      y.push(String(i));
      i += 1;
    }
    return y;
  }, []);

  const dateChanged = React.useCallback(() => {
    const [dateMonth, dateYear] = formController.getValues(['dateMonth', 'dateYear']);
    const date = moment(`${dateYear}-${dateMonth}-01`).tz('Asia/Manila');
    const startDate = date.clone().startOf('month').toISOString(true);
    const endDate = date.clone().endOf('month').toISOString(true);
    formController.setValue('startDate', startDate);
    formController.setValue('endDate', endDate);
  }, [formController]);

  return (
    <Box sx={{ pt: '10px', pb: '10px', width: '100%' }}>
      <Stack direction="row" spacing={1}>
        <LocationAutocomplete
          name="location"
          label="Station"
          textFieldProps={{ required: true }}
        />
        <Select
          name="dateMonth"
          label="Month"
          options={months}
          selectProps={{ sx: { minWidth: '140px' } }}
          getOptionLabel={(x) => x.label}
          getOptionValue={(x) => x.value}
          onChange={dateChanged}
        />
        <Select
          name="dateYear"
          label="Year"
          options={years}
          selectProps={{ sx: { minWidth: '90px' } }}
          onChange={dateChanged}
        />
      </Stack>
    </Box>
  );
};
