import { DialogContent, DialogTitle, Link, Stack } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import React from 'react';
import { getDsrShiftList } from '../../../apis/dsr.api';
import { DataTableStandalone } from '../../../components/commons/data-table/data-table-standalone';
import { useColumns } from './summary-dialog-column';

export type DsrSummaryDialogModuleProps = {
  label: string;
  startDate: string;
  endDate: string;
  stationCode: string;
};

export function DsrSummaryDialogModule(props: DsrSummaryDialogModuleProps) {
  const { label, startDate, endDate, stationCode } = props;

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const columns = useColumns();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = React.useCallback(async () => {
    setLoading(true);
    const res = await getDsrShiftList({
      query: { stationCode, startDate, endDate },
    }).finally(() => setLoading(false));
    const records = res.data?.data?.records;
    records.sort((a: any, b: any) =>
      String(a.shiftCode).localeCompare(b.shiftCode)
    );
    setData(records);
  }, [stationCode, startDate, endDate]);

  React.useEffect(() => {
    if (open) {
      getData();
    }
  }, [open, getData]);

  return (
    <>
      <Link
        onClick={handleClickOpen}
        sx={{ cursor: 'pointer', textDecoration: 'none' }}
      >
        {label}
      </Link>

      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DataTableStandalone
              data={data}
              columns={columns}
              loading={loading}
              perPage={3}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
