import ListIcon from '@mui/icons-material/List';
import { IconButton } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Path from '../../enums/path';
import { useAppContext } from '../../hooks/useApp';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function AggregatedSummaryActions(info: CellContext<unknown, unknown>) {
  const navigate = useNavigate();
  const { initState } = useAppContext();
  const batchId: string = info.row.getValue('batchId');

  const basePath = Path.AggregatedList;
  const openList = React.useCallback(() => {
    initState.setState('aggregated-filter', (curr: any) => ({
      ...curr,
      uploadedBy: info.row.getValue('uploadedBy'),
      syncStatus: info.row.getValue('syncStatus'),
    }));
    navigate(`${basePath}/${batchId}`);
  }, [basePath, navigate, batchId, initState, info]);

  return (
    <IconButton onClick={openList} title="batch details">
      <ListIcon />
    </IconButton>
  );
}
