import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { format as formatDate } from 'date-fns';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export const columns: ColumnDef<unknown>[] = [
  {
    accessorKey: "stationBusinessName",
    header: "Station Business Name",
  },
  {
    accessorKey: "paymentMethod",
    header: "Payment Method",
  },
  {
    accessorKey: "paymentProcessor",
    header: "Payment Processor",
  },
  {
    accessorKey: "transactionDateTime",
    header: () => <Box sx={{ minWidth: '150px' }}>Transaction Date and Time</Box>,
    cell: (info) => {
      return formatDate(String(info.getValue()), 'MMM dd, yyyy hh:mm aa');
    },
  },
  {
    accessorKey: "amount",
    header: "Amount",
    cell: (info) => {
      return <Box textAlign='right'>{Number(info.getValue()).toFixed(2)}</Box>;
    },
  },
  {
    accessorKey: "createdAt",
    header: () => <Box sx={{ minWidth: '150px' }}>Upload Date to Aggregator</Box>,
    cell: (info) => {
      return formatDate(String(info.getValue()), 'MMM dd, yyyy hh:mm aa');
    },
    enableHiding: true,
  },
  {
    accessorKey: "uploadedBy",
    header: "Uploaded By",
  },
  {
    accessorKey: "settlementReportUniqueIdentifier",
    header: "Settlement Report Unique Identifier",
  },
  {
    accessorKey: "cardType",
    header: "Card Type / Card Number",
  },
];
