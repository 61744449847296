import { createTheme } from "@mui/material/styles";

const prodTheme = createTheme({
  // modify styles, colors here
  palette: {
  },
  components: {
    MuiToolbar: {
      defaultProps: {
        sx: {
          color: '#fafafa',
          backgroundColor: '#344955',
        },
      },
    },
    MuiTableHead: {
      defaultProps: {
        sx: {
          backgroundColor: '#eeeeee',
        }
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          padding: '10px',
        }
      }
    }
  }
});

export default prodTheme;
