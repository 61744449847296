import { Box, SxProps } from "@mui/material";
import React from "react";

export type DisplayLongTextProps = {
  children: string | undefined;
  sx?: SxProps;
}

export function DisplayLongText(props: DisplayLongTextProps) {
  const { sx } = props;

  const [hidden, setHidden] = React.useState(true);

  const onClick = () => {
    setHidden((curr) => !curr); // toggle
  }

  return (
    <Box
      sx={{
        minWidth: '200px',
        maxWidth: '300px',
        ...sx,
      }}
    >
      <Box
        onClick={onClick}
        component="div"
        sx={{
          textOverflow: 'ellipsis',
          overflow: hidden ? 'hidden' : undefined,
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}