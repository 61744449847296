import { Button, ButtonProps } from "@mui/material";
import { AxiosResponse } from "axios";
import React from "react";

export interface FileDownloadProps extends Omit<ButtonProps, 'onClick'> {
  filename: string;
  onDownload: () => Promise<AxiosResponse>;
}

export function FileDownload(props: FileDownloadProps) {
  const {
    children,
    filename,
    onDownload,
    ...buttonProps
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(async (e) => {
    if (!onDownload) return;
    setLoading(true);
    await onDownload()
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        // TODO: Handling Errors
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [onDownload, filename])

  return (
    <Button
      {...buttonProps}
      disabled={loading}
      onClick={onClick}
    >
      {children || 'Download'}
    </Button>
  )
}
