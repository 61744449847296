import { Grid, Stack } from "@mui/material";

import { DataTable } from "components/commons/data-table/data-table";
import { DataTableControl } from "components/commons/data-table/data-table-control";
import React from "react";
import { getDsrItemReceiptList, GetDsrItemReceiptListProps } from "../../../apis/dsr.api";
import { DataTablePagination } from "../../../components/commons/data-table/data-table-pagination";
import { useFormController } from "../../../components/commons/form/controller/form-controller";
import { useColumns } from "./item-receipt-column";
import { DsrItemReceiptExport } from "./item-receipt-export";
import { useInitialFilterState } from "./item-receipt-filter.state";

export type DsrItemReceiptListProps = {};

export function DsrItemReceiptListModule(props: DsrItemReceiptListProps) {
  const columns = useColumns();
  const initialFilterState = useInitialFilterState();
  const formController = useFormController();

  const [filter, setFilter] = React.useState<GetDsrItemReceiptListProps['query']>({});

  const onChange = React.useCallback((data: any) => {
    setFilter({
      subsidiary: data.subsidiary === '' ? undefined : data.subsidiary,
      stationCode: data.location?.stationCode,
      shiftCode: data.shiftCode,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }, [])

  React.useEffect(() => {
    onChange(formController.getValues());
    const { unsubscribe } = formController.watch(onChange);
    return () => unsubscribe();
  }, [onChange, formController]);

  return (
    <>
      <DataTableControl
        columns={columns}
        listFn={getDsrItemReceiptList}
        initialApiProps={initialFilterState}
        autoCommit={false}
        filter={filter}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <DsrItemReceiptExport />
              </Stack>
            </Grid>
          </Grid>

          <DataTable
            initialTableState={{
              columnVisibility: {
                dsrId: false,
                date: false,
                dateTime: false,
                createdAt: false,
                shiftReportId: false,
                location: false,
                itemCode: false,
                itemReceiptId: false,
                refPlateNumber: false,
                attendant: false,
                crew: false,
                cashier: false,
                trainee: false,
                beforeReceivingCm: false,
                beforeReceivingQty: false,
                afterReceivingCm: false,
                afterReceivingQty: false,
              }
            }}
          />
          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
