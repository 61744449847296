import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem
} from '@mui/material';
import MuiSelect, {
  SelectProps as MuiSelectProps
} from "@mui/material/Select";
import { useController } from 'react-hook-form';
import { useFormController } from '../controller/form-controller';

export type SelectProps<Value = any> = {
  name: string;
  options: Value[];
  label?: string;
  loading?: boolean;
  selectProps?: Partial<MuiSelectProps<Value>>;
  formControlProps?: FormControlProps;
  getOptionLabel?: (option: Value) => string,
  getOptionValue?: (option: Value) => string,
  blankLabel?: string,
  onChange?: (value: Value) => void;
}

export function Select<Value = any>(props: SelectProps<Value>) {
  const {
    name,
    label,
    options,
    selectProps,
    formControlProps,
    getOptionLabel,
    getOptionValue,
    blankLabel = '<none>',
    onChange: customOnChange,
  } = props;

  const { control, getValues } = useFormController();
  const { field } = useController({
    name,
    control,
  });

  return (
    <FormControl {...formControlProps}>
      <InputLabel id={`labelid-${name}`} shrink>
        {label}
      </InputLabel>
      <MuiSelect
        {...field}
        {...selectProps}
        labelId={`labelid-${name}`}
        label={label}
        onChange={(event) => {
          field.onChange(event);
          if (customOnChange) {
            const [option] = getValues([name]);
            customOnChange(option);
          }
        }}
      >
        {options.map((x) => {
          let l = String(x);
          let v = String(x);
          if (getOptionLabel) {
            l = getOptionLabel(x);
          }
          if (getOptionValue) {
            v = getOptionValue(x);
          }
          if (v === '' || v === null || v === undefined) {
            l = blankLabel;
          }
          return (
            <MenuItem key={`key-${v}`} value={v}>
              {l}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};
