import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ColumnDef } from '@tanstack/react-table';
import { CustomerResponse } from '../../apis/customer.api';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {

  const columns: ColumnDef<CustomerResponse>[] = [
    {
      accessorKey: "name",
      header: "ID",
    },
    {
      accessorKey: "displayName",
      header: "Name",
    },
    {
      accessorKey: "allowCredit",
      header: "Allow Credit",
      cell: (info) => {
        if (info.getValue()) {
          return <CheckBoxIcon />;
        }
        return <CheckBoxOutlineBlankIcon />
      },
    },
  ];

  return columns;
}
