import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { ColumnDef } from '@tanstack/react-table';
import log from 'loglevel';
import { ImportFileStatusEnum } from './aggregator-import-files-status';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'delete',
      header: '',
      cell: (info) => {
        const row = info.row.original as any;
        return (
          <IconButton
            title={'Remove File'}
            disabled={row.disabled}
            onClick={row.deleteFile}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
    {
      id: 'filename',
      accessorKey: 'filename',
      header: 'Filename',
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.status) return <></>;
        return (
          <Chip
            color={row.color}
            icon={row.disabled ? row.icon : undefined}
            size={'small'}
            label={String(row.status).toUpperCase()}
            key={row.id}
            sx={{ mb: '10px', mr: '10px' }}
          />
        )
      }
    },
    {
      id: 'result',
      // accessorKey: 'result',
      header: 'Result',
      cell: (info) => {
        const row = info.row.original as any;
        if (!row.result) return <></>;
        const result = row.result;
        let message = row.status === ImportFileStatusEnum.SUCCESS
          ? `${result.totalInsert} row(s) successfully saved;`
          : undefined;

        if (row.status === ImportFileStatusEnum.ERROR) {
          message = result?.message || result?.error?.message || 'Unknown Error';
        }

        let downloadErrors = <></>;
        if (result?.linkAttached) {
          downloadErrors = (
            <>
              {'. '}
              <a href={result.linkAttached}>
                Download Error List
              </a>
            </>
          )
        }
        return (
          <>
            {message}{downloadErrors}
          </>
        );
      },
    },
    {
      id: 'details',
      accessorKey: 'details',
      header: 'Show Details',
      cell: (info) => {
        const row = info.row.original as any;
        log.debug(row.filename, row.results);
        if (row.status !== ImportFileStatusEnum.SUCCESS
          && row.status !== ImportFileStatusEnum.ERROR) return <></>;
        return (
          <IconButton
            title={'Show Details'}
            disabled={row.disabled}
            onClick={row.showStatus}
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
  ];

  return columns;
}
