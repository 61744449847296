import moment from 'moment-timezone';
import { GetDsrTransactionListProps } from '../../../apis/dsr.api';

export function useInitialFilterState() {
  const now = Date.now();
  const endDate = moment(now).tz('Asia/Manila').endOf('month');
  const startDate = moment(now).startOf('month');

  const initialFilterState: GetDsrTransactionListProps = {
    query: {
      searchKey: '',
      startDate: startDate.toISOString(true),
      endDate: endDate.toISOString(true),
      shiftReportId: '',
      dsrId: '',
      stationCode: '',
      subsidiary: '',
      shiftCode: '',
    },
  };

  return initialFilterState;
}