import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'createdAt',
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'inventoryId',
      accessorKey: "inventoryId",
      header: "Inventory ID",
    },
    {
      id: 'shiftReportId',
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: 'dsrId',
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: 'subsidiary',
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: 'stationCode',
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: 'stationName',
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: 'shiftCode',
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: 'inventoryMovementId',
      accessorKey: "inventoryMovementId",
      header: "Inventory Movement ID",
    },
    {
      id: 'dateTime',
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'date',
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'location',
      accessorKey: "location",
      header: "Location",
    },
    {
      id: 'itemCode',
      accessorKey: "itemCode",
      header: "Item Code",
    },
    {
      id: 'productName',
      accessorKey: "productName",
      header: "Product Name",
    },
    {
      id: 'unitPrice',
      accessorKey: "unitPrice",
      header: "Unit Price",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'beginningCm',
      accessorKey: "beginningCm",
      header: "Beginning Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'beginningQuantity',
      accessorKey: "beginningQuantity",
      header: "Beginning Quantity",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'closingCm',
      accessorKey: "closingCm",
      header: "Closing Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'closingQuantity',
      accessorKey: "closingQuantity",
      header: "Closing Quantity",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'beforeReceivingCm',
      accessorKey: "beforeReceivingCm",
      header: "Before Receiving Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'beforeReceivingQuantity',
      accessorKey: "beforeReceivingQuantity",
      header: "Before Receiving Quantity",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'purchases',
      accessorKey: "purchases",
      header: "Purchases",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'afterReceivingCm',
      accessorKey: "afterReceivingCm",
      header: "After Receiving Cm",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'afterReceivingQuantity',
      accessorKey: "afterReceivingQuantity",
      header: "After Receiving Quantity",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'availableForSales',
      accessorKey: "availableForSales",
      header: "Available For Sales",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'goodsSoldPerTrx',
      accessorKey: "goodsSoldPerTrx",
      header: "Goods Sold Per Trx",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'goodsSoldPerTotalizer',
      accessorKey: "goodsSoldPerTotalizer",
      header: "Goods Sold Per Totalizer",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'lessOthers',
      accessorKey: "lessOthers",
      header: "Less Others",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'endingInventory',
      accessorKey: "endingInventory",
      header: "Ending Inventory",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'variance',
      accessorKey: "variance",
      header: "Variance",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'deliveryVariance',
      accessorKey: "deliveryVariance",
      header: "Delivery Variance",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'totalVariance',
      accessorKey: "totalVariance",
      header: "Total Variance",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'percentVariance',
      accessorKey: "percentVariance",
      header: "Percent Variance",
      cell: (info) => <DisplayFloat isPercent decimalPlaces={2}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'computationPriority',
      accessorKey: "computationPriority",
      header: "Computation Priority",
    },
  ];

  return columns;
}
