import { Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { DisplayDate } from '../../components/commons/data-display/DisplayDate';
import { DisplayFloat } from '../../components/commons/data-display/DisplayFloat';
import { AggregatedSyncStatusEnum } from '../../enums/sync-status';
import { AggregatedSummaryActions } from './aggregated-summary-actions';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'actions',
      header: 'Actions',
      cell: AggregatedSummaryActions,
    },
    {
      id: 'batchId',
      accessorKey: 'batchId',
      header: 'Batch ID',
      enableHiding: true,
    },
    {
      id: 'stationCode',
      accessorKey: 'stationCode',
      header: 'Station Code',
    },
    {
      id: 'uploadedBy',
      accessorKey: 'uploadedBy',
      header: 'Uploaded By',
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'syncStatus',
      accessorKey: 'syncStatus',
      header: 'Sync Status',
      cell: (info) => {
        const value = String(info.getValue() || '');
        if (value === AggregatedSyncStatusEnum.ERROR) {
          return (
            <Typography variant="body1" sx={{ color: 'red' }}>
              {value}
            </Typography>
          );
        }
        return <Typography variant="body1">{value}</Typography>;
      },
    },
    {
      id: 'maxSyncedAt',
      accessorKey: "maxSyncedAt",
      header: "Last Synced At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>
    },
    {
      id: 'recordCount',
      accessorKey: 'recordCount',
      header: 'Record Count',
      cell: (info) => (
        <DisplayFloat decimalPlaces={0}>
          {info.getValue() as string}
        </DisplayFloat>
      ),
    },
  ];

  return columns;
}
