import {
  GoogleAuthProvider,
  IdTokenResult,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from '@firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import log from 'loglevel';
import moment from 'moment-timezone';
import qs from 'query-string';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import Path from '../enums/path';
import { Environment } from '../environments/index.type';
import { useAppContext } from './useApp';

export type UseFirebaseProps = {
  env?: Environment;
};

export function useFirebase(props: UseFirebaseProps) {
  const { env } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [app, setApp] = React.useState<FirebaseApp>();
  // const [db, setDb] = React.useState<Firestore>();
  const [user, setUser] = React.useState<User>();
  const [token, setToken] = React.useState<string>();
  const [claims, setClaims] = React.useState<IdTokenResult>();
  const [initialized, setInitialized] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [loginError, setLoginError] = React.useState<any>('');
  const initFirebase = React.useCallback(() => {
    if (!env?.firebaseConfig) return;
    if (initialized) return;
    setInitialized(true);
    const a = initializeApp(env.firebaseConfig);
    const d = getFirestore(a);
    setApp(a);
    // setDb(d);
    log.debug('firebase init: ', initialized);
  }, [initialized, env]);

  const loginHandler = async () => {
    const auth = getAuth(app); // Get the authentication instance
    const provider = new GoogleAuthProvider();
    setLoginError('');
    return signInWithPopup(auth, provider)
      .then((result) => {
        const search = qs.parse(location.search);
        const toPath = String(search.redirect || '') || Path.Slash;
        navigate(toPath);
      })
      .catch((error) => {
        log.error(error);
        const jsonString = error.message.match(/\{.*\}/)[0];

        // Parse the JSON string
        const errorObject = JSON.parse(jsonString);

        // Extract the desired fields
        const errorMessage = errorObject.error.message;
        const errorStatus = errorObject.error.status;

        setLoginError(`${errorStatus} ${errorMessage}`);
      });
  };

  const logoutHandler = async () => {
    const auth = getAuth(app); // Get the authentication instance
    return signOut(auth)
      .then(() => {
        setLoaded(false);
        navigate(Path.Auth);
      })
      .catch((error) => {
        log.error('Login error', error);
      });
  };

  // Subscribe to auth state changes
  React.useEffect(() => {
    if (!initialized || !app) return;
    const auth = getAuth(app);
    log.debug(`subsribing...`);
    const unsubscribe = onAuthStateChanged(auth, (u: any) => {
      log.debug(`subscribe firebase auth change`, u);
      setUser(u || undefined);
      if (u?.accessToken) {
        setToken(u?.accessToken);
      }
      setLoaded(true);
    });

    return () => {
      log.debug('unsubscribe firebase auth change');
      unsubscribe();
    };
  }, [initialized, app, user]);

  // refresh token
  React.useEffect(() => {
    if (!user || !claims) return;
    const nowMs = Date.now();
    const expireTimeMs = moment(claims.expirationTime).valueOf();

    // 10 minutes before expiry
    let nextRefreshMs = expireTimeMs - nowMs - 1000 * 60 * 10;
    log.debug('next token refresh', moment(nowMs + nextRefreshMs).toDate());

    const t = setTimeout(() => {
      log.debug('refreshing token...');
      user.getIdToken(true).then((token) => {
        setToken(token);
      });
    }, nextRefreshMs);

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [user, claims]);

  // update claims and token
  React.useEffect(() => {
    if (!user || !user.getIdTokenResult) return;
    if (token && token === claims?.token) return;
    user.getIdTokenResult().then((c) => {
      log.debug('updating claims', c);
      setToken(c.token);
      setClaims(c);
    });
  }, [user, token, claims]);

  return {
    initialized,
    loaded,
    initFirebase,
    loginHandler,
    logoutHandler,
    user,
    token,
    claims,
    loginError,
  };
}

export function FirebaseInit() {
  const { firebase } = useAppContext();
  React.useEffect(() => firebase.initFirebase(), [firebase]);
  return <></>;
}
