import {
  CircularProgress,
  FormControl,
  FormControlProps,
  TextField,
  TextFieldProps
} from '@mui/material';
import MuiAutocomplete, { AutocompleteInputChangeReason, AutocompleteRenderInputParams, AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import React from 'react';
import { useController } from 'react-hook-form';
import { useFormController } from '../controller/form-controller';

export type AutocompleteProps<
  Value = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  name: string;
  options: Value[];
  label?: string;
  defaultValue?: Value;
  loading?: boolean;
  onChange?: (value?: Value) => void;
  autocompleteProps?: Partial<MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>>;
  formControlProps?: FormControlProps;
  textFieldProps?: TextFieldProps;
}

export function Autocomplete<Value = any>(props: AutocompleteProps<Value>) {
  const {
    name,
    label,
    options,
    loading,
    onChange: customOnChange,
    autocompleteProps,
    formControlProps,
    textFieldProps,
  } = props;

  const {
    onInputChange: customInputChange,
  } = autocompleteProps || {};

  const { control } = useFormController();
  const { field } = useController({
    name,
    control,
  });

  const [value, setValue] = React.useState<Value | null>(field.value || null);
  const [inputValue, setInputValue] = React.useState('');

  const onInputChange = (e: React.SyntheticEvent, v: string, r: AutocompleteInputChangeReason) => {
    setInputValue(v);
    if (customInputChange) {
      customInputChange(e, v, r);
    }
  };

  return (
    <FormControl {...formControlProps}>
      <MuiAutocomplete
        {...field}
        {...autocompleteProps}
        value={value}
        loading={loading}
        options={options}
        onChange={(event, value) => {
          setValue(value);
          field.onChange(value);
          if (customOnChange) customOnChange(value || undefined);
        }}
        onInputChange={onInputChange}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            {...textFieldProps}
            value={inputValue}
            label={label}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};
