import React from "react";
import { getLocationList, GetLocationQueryApi, LocationResponse } from "../../../../apis/location.api";
import { useAppContext } from "../../../../hooks/useApp";
import { useFormController } from "../controller/form-controller";
import { Autocomplete, AutocompleteProps } from "./autocomplete";

const defaultQuery: GetLocationQueryApi = { page: 1, perPage: 20 };

type Value = Partial<LocationResponse>;
const initStateName = 'location-autocomplete';
export interface LocationAutocompleteProps extends Partial<AutocompleteProps<Value>> {
  subsidiary?: string;
}

export function LocationAutocomplete(props: LocationAutocompleteProps) {
  const {
    name = 'location-autocomplete',
    subsidiary,
    autocompleteProps,
    textFieldProps,
  } = props;

  const { api, initState } = useAppContext();
  const { getValues } = useFormController();
  const [defaultValue] = getValues([name]);

  const initList = (initState.getState(initStateName) || []) as LocationResponse[];
  const [records, setRecords] = React.useState<LocationResponse[]>(initList);
  const [loading, setLoading] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const [error, setError] = React.useState('');
  const [selected, setSelected] = React.useState<Value | undefined>(defaultValue);
  const inputRef = React.useRef<HTMLInputElement>();

  const options = React.useMemo(() => {
    let list: Value[] = [];
    if (selected) {
      list.push(selected);
    }
    records
      .filter((x) => subsidiary ? x.subsidiary === subsidiary : true)
      .filter((x) => selected ? x.stationCode !== selected?.stationCode : true)
      .forEach((x) => list.push(x));
    return list;
  }, [records, subsidiary, selected]);

  const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    let t: any;
    if (reason === 'input') {
      t = setTimeout(() => {
        if (inputRef.current?.value === value) {
          console.log('searchKey =', value);
          setSearchKey(value || '');
        }
      }, 500);
    }
    return () => {
      if (t) {
        clearTimeout(t);
      }
    }
  };

  const apiParams = React.useMemo<GetLocationQueryApi>(() => {
    return {
      ...defaultQuery,
      searchKey,
      subsidiary,
    }
  }, [searchKey, subsidiary]);

  /**
   * 
   */
  React.useEffect(() => {
    let active = true;

    if (!api.initialized) return;
    setLoading(true);

    (async () => {
      const result = await getLocationList({ query: apiParams })
      if (active) {
        const newOptions = result.data.data.records;
        setRecords((curr) => {
          const list = [...curr];
          newOptions.forEach((n) => {
            const existing = list.find((l) => l.locationId === n.locationId);
            if (!existing) {
              list.push(n);
            } else {
              Object.assign(existing, n);
            }
          });
          return list;
        });
      }
    })().finally(() => setLoading(false));

    return () => {
      active = false;
    }
  }, [api.initialized, apiParams, subsidiary]);

  React.useEffect(() => {
    const loc = options.find((x) => x.stationCode === selected?.stationCode);
    const isValid = (selected ? loc !== undefined : true)
      && (subsidiary && selected ? selected?.subsidiary === subsidiary : true);
    if (!loading && !isValid) {
      setError('Selected station is invalid');
    } else {
      setError('');
    }
  }, [selected, options, loading, subsidiary]);

  React.useEffect(() => {
    initState.setState(initStateName, records);
  }, [records, initState]);

  return (
    <Autocomplete<Value>
      {...props}
      name={name}
      options={options}
      loading={loading}
      defaultValue={selected}
      onChange={(value) => setSelected(value || undefined)}
      autocompleteProps={{
        sx: { minWidth: '270px' },
        ...autocompleteProps,
        getOptionLabel: (option) => `${option.stationName} [${option.stationCode}]`,
        onInputChange,
      }}
      textFieldProps={{
        ...textFieldProps,
        inputRef: inputRef,
        error: Boolean(error),
        helperText: error,
      }}
    />
  )
}