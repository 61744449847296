import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: 'createdAt',
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'depositId',
      accessorKey: "depositId",
      header: "Deposit ID",
    },
    {
      id: 'shiftReportId',
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: 'dsrId',
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: 'subsidiary',
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: 'stationCode',
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: 'stationName',
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: 'cashier',
      accessorKey: "cashier",
      header: "Cashier",
    },
    {
      id: 'shiftCode',
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: 'date',
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'dateTime',
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'location',
      accessorKey: "location",
      header: "Location",
    },
    {
      id: 'depositDateTime',
      accessorKey: "depositDateTime",
      header: "Deposit Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: 'amountDeposited',
      accessorKey: "amountDeposited",
      header: "Amount Deposited",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'partialAmountDeposited',
      accessorKey: "partialAmountDeposited",
      header: "Partial Amount Deposited",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: 'depositType',
      accessorKey: "depositType",
      header: "Deposit Type",
    },
    {
      id: 'remarks',
      accessorKey: "remarks",
      header: "Remarks",
    },
    {
      id: 'siNum',
      accessorKey: "siNum",
      header: "SI Num",
    },
    {
      id: 'refName',
      accessorKey: "refName",
      header: "Ref Name",
    },
    {
      id: 'cashAccount',
      accessorKey: "cashAccount",
      header: "Cash Account",
    },
    {
      id: 'paymentMode',
      accessorKey: "paymentMode",
      header: "Payment Mode",
    },
    {
      id: 'refNumber1',
      accessorKey: "refNumber1",
      header: "Ref Number 1",
    },
    {
      id: 'refNumber2',
      accessorKey: "refNumber2",
      header: "Ref Number 2",
    },
    {
      id: 'vendorCode',
      accessorKey: "vendorCode",
      header: "Vendor Code",
    },
    {
      id: 'lookup',
      accessorKey: "lookup",
      header: "Lookup",
    },
  ];

  return columns;
}
