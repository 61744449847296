import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  // modify styles, colors here
  palette: {
    // example
    // primary: {
    //   main: '#f0f0f0',
    //   light: '#fbc02d',
    //   dark: '#f57f17',
    //   contrastText: '#000',
    // },
    // secondary: {
    //   main: '#4e342e',
    //   light: '#5d4037',
    //   dark: '#3e2723',
    //   contrastText: '#fff',
    // },
  },
  components: {
    MuiToolbar: {
      defaultProps: {
        sx: {
          color: '#000',
          backgroundColor: '#dddddd',
        },
      },
    },
    MuiTableHead: {
      defaultProps: {
        sx: {
          backgroundColor: '#eeeeee',
        }
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          padding: '10px',
        }
      }
    },
  }
});

export default defaultTheme;
