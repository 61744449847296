import { Grid, Stack } from '@mui/material';
import { DataTable } from 'components/commons/data-table/data-table';
import { DataTableControl } from 'components/commons/data-table/data-table-control';
import { DataTablePagination } from 'components/commons/data-table/data-table-pagination';
import { DataTableSearchBox } from 'components/commons/data-table/data-table-search-box';
import Intro from 'components/commons/intro/intro';
import { getCustomers } from '../../apis/customer.api';
import { useColumns } from './customer-list-column';
import { initialFilterState } from './customer-list-filter.state';

export type CustomerListProps = {};

export function CustomerListModule(props: CustomerListProps) {
  const columns = useColumns();

  return (
    <>
      <Intro
        title="Customers Master List"
        subtitle=""
      />
      <DataTableControl
        columns={columns}
        listFn={getCustomers}
        initialApiProps={initialFilterState}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <DataTableSearchBox autoFocus />
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end">
              </Stack>
            </Grid>
          </Grid>

          <DataTable />

          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
