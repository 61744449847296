import EastIcon from '@mui/icons-material/East';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { DateView } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import moment from 'moment-timezone';
import React from 'react';
import { useDataTableContext } from './data-table-control';

export type DataTableDateRangeProps = {
  showDateText?: boolean;
};

export function DataTableDateRange(props: DataTableDateRangeProps) {
  const { showDateText = false } = props;
  const context = useDataTableContext();

  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    context.startDate ? moment(context.startDate).tz('Asia/Manila') : undefined
  );
  const [endDate, setEndDate] = React.useState(
    context.endDate ? moment(context.endDate).tz('Asia/Manila') : undefined
  );

  const handleSave = () => {
    context.setStartDate(startDate ? startDate.toISOString(true) : undefined);
    context.setEndDate(endDate ? endDate.toISOString(true) : undefined);
  };

  const handleChange =
    (key: string) =>
    (value: any, state?: PickerSelectionState, view?: DateView) => {
      if (key === 'startDate') {
        setStartDate(value);
      } else if (key === 'endDate') {
        setEndDate(value);
      }
    };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // restore original
    setStartDate(
      context.startDate
        ? moment(context.startDate).tz('Asia/Manila')
        : undefined
    );
    setEndDate(
      context.endDate ? moment(context.endDate).tz('Asia/Manila') : undefined
    );
    setOpen(false);
  };

  const dateStr = React.useMemo(() => {
    const startStr = startDate ? startDate.format('MMM DD, YYYY') : '?';
    const endStr = endDate ? endDate.format('MMM DD, YYYY') : '?';
    if (!startDate && !endDate) {
      return 'no date filters';
    }
    return (
      <>
        from <b>{startStr}</b> to <b>{endStr}</b>
      </>
    );
  }, [startDate, endDate]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleOpen}>
          <EditCalendarIcon />
        </IconButton>
        <Box>{!showDateText ? undefined : dateStr}</Box>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        // fullWidth
        maxWidth="md"
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Date Range</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: '10px' }} textAlign="center">
            <Box>{dateStr}</Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <DateCalendar
                  reduceAnimations
                  disableFuture
                  value={startDate}
                  maxDate={endDate}
                  onChange={handleChange('startDate')}
                />
                <EastIcon />
                <DateCalendar
                  reduceAnimations
                  disableFuture
                  minDate={startDate}
                  value={endDate}
                  onChange={handleChange('endDate')}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
