import moment from 'moment-timezone';
import { BasicDsrQueryApi } from '../../apis/dsr.api';
import { LocationResponse } from '../../apis/location.api';

export interface FilterState extends BasicDsrQueryApi {
  dateMonth?: string;
  dateYear?: string;
  location?: Partial<LocationResponse>;
}

export function useInitialFilterState() {

  const now = Date.now();
  const endDate = moment(now).tz('Asia/Manila').endOf('month');
  const startDate = moment(now).startOf('month');
  const defaultMonth = startDate.format('MM');
  const defaultYear = startDate.format('YYYY');

  const initialFilterState: FilterState = {
    startDate: startDate.toISOString(true),
    endDate: endDate.toISOString(true),
    dateMonth: defaultMonth,
    dateYear: defaultYear,
  };

  return initialFilterState;
}
