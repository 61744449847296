import { Box, SxProps } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';

export type DisplayDateProps = {
  children: string | number | null | undefined;
  dateFormat?: string | null;
  timeFormat?: string | null;
  sx?: SxProps;
  /** @deprecated */
  format?: string;
}

export function DisplayDate(props: DisplayDateProps) {
  const {
    children,
    sx,
    dateFormat = 'MMM DD, YYYY',
    timeFormat = 'h:mm A',
  } = props;

  const displayValue = React.useMemo(() => {
    const date = moment(children);
    let dateStr = '';
    if (children === null || children === undefined || children === '') {
      dateStr = '';
    } else if (!date.isValid()) {
      dateStr = `!!! ${children}`;
    } else {
      if (dateFormat !== null) {
        dateStr = date.format(dateFormat).replace(/\s/g, '\u00A0');
      }
      if (dateStr !== '') {
        dateStr += ' ';
      }
      if (timeFormat !== null) {
        dateStr += date.format(timeFormat).replace(/\s/g, '\u00A0');
      }
    }
    return dateStr;
  }, [children, dateFormat, timeFormat]);

  return (
    <Box sx={{ ...sx }}>
      {displayValue}
    </Box>
  )
}