import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Chip, FormControl, FormHelperText, SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import log from 'loglevel';
import React, { useEffect, useRef } from 'react';
import { DropEvent, DropzoneOptions, useDropzone } from 'react-dropzone';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface FileUploadProps
  extends Omit<DropzoneOptions, 'onDrop' | 'onDropAccepted'> {
  sx?: SxProps;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  buttonText?: string;
  value?: File[];
  error?: boolean;
  helperText?: any;
  onChange?: (files: File[]) => void;
  name?: string;
  ref?: any;
}

export function FileUpload(props: FileUploadProps) {
  const {
    name,
    value,
    onChange,
    sx,
    buttonText = 'Upload File',
    buttonProps,
    disabled,
    error,
    helperText,
    ...options
  } = props;

  const [fileList, setFileList] = React.useState<File[]>(value || []);
  const dropzoneRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value == undefined) {
      handleReset();
    }
  }, [value]);

  const onDropAccepted = React.useCallback(
    (d: File[], e: DropEvent) => {
      log.debug('file/s selected', d);
      setFileList(d);
      if (onChange) {
        onChange(d);
      }
    },
    [onChange]
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    ...options,
    disabled,
    onDropAccepted,
    noClick: true,
    noKeyboard: true,
  });

  const onDelete = React.useCallback(
    (name: string) => () => {
      if (name) {
        const files = (value || fileList).filter((x) => x.name !== name);
        onDropAccepted(files, {
          target: { files: [...files] },
        } as any);
      }
    },
    [value, fileList, onDropAccepted]
  );

  const files = React.useMemo(() => {
    const l = value || fileList;
    return l.map((file) => {
      return (
        <Chip
          sx={{ mr: '5px', mb: '5px' }}
          key={file.name}
          label={file.name}
          variant="outlined"
          onDelete={onDelete(file.name)}
        />
      );
    });
  }, [value, fileList, onDelete]);

  const errors = React.useMemo(() => {
    const messages = [];
    if (helperText)
      messages.push(
        <React.Fragment key="helper">
          {helperText}
          <br />
        </React.Fragment>
      );
    fileRejections.forEach((fr) => {
      const fname = fr.file.name;
      const m = fr.errors.map((e) => e.message).join('; ');
      messages.push(
        <React.Fragment key={fname}>
          <b>{fname}</b> - {m}
          <br />
        </React.Fragment>
      );
    });
    return messages;
  }, [helperText, fileRejections]);

  const handleReset = () => {
    setFileList([]);
    if (dropzoneRef.current) {
      dropzoneRef.current.value = ''; // Only set if not null
    }
    // if (onChange) {
    //   onChange([]);
    // }
  };
  return (
    <Box {...getRootProps()} sx={sx}>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
        {...buttonProps}
      >
        {buttonText}
        <VisuallyHiddenInput
          name={name}
          {...getInputProps()}
          ref={dropzoneRef}
        />
      </Button>
      <Box sx={{ pt: files.length > 0 ? '10px' : undefined }}>{files}</Box>
      <FormControl error={error || fileRejections.length > 0}>
        <FormHelperText>{errors}</FormHelperText>
      </FormControl>
    </Box>
  );
}
