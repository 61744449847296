import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useDataTableContext } from './data-table-control';

export interface DataTableSearchBoxProps extends Omit<Omit<TextFieldProps, "variant">, "value"> {
  onChangeSearch?: (value: string | undefined) => void;
}

export function DataTableSearchBox(props: DataTableSearchBoxProps) {
  const { onChangeSearch: customOnChangeSearch, ...textFieldProps } = props;
  const context = useDataTableContext();

  const [searchKey, setSearchKey] = React.useState(context.searchKey);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchKey(e.target.value || '');
  }

  // delay searching when typing
  React.useEffect(() => {
    if (searchKey === context.searchKey) return;
    const n = setTimeout(() => {
      if (customOnChangeSearch) {
        customOnChangeSearch(searchKey);
      } else {
        context.setSearchKey(searchKey);
      }
      context.setPage(1);
    }, 1000);
    return () => clearTimeout(n);
  }, [context, searchKey, customOnChangeSearch]);

  return (
    <Box>
      <TextField
        focused
        name='search'
        size='small'
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
        }}
        {...textFieldProps}
        disabled={context.loading}
        onChange={onChangeSearch}
        value={searchKey}
      />
    </Box>
  );
}
