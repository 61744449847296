import moment from 'moment-timezone';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AggregatedSyncStatusEnum } from '../../enums/sync-status';
import { useAppContext } from '../../hooks/useApp';

export function useInitialFilterState() {
  const { batchId } = useParams();
  const { firebase, initState } = useAppContext();

  const previousState = initState.getState('aggregated-filter');
  const endDate = moment().tz('Asia/Manila').endOf('day');
  const startDate = moment(endDate).subtract(3, 'days').startOf('day');

  const initialFilterState = React.useMemo(() => {
    const filter: any = {
      batchId: batchId ? batchId : undefined,
      uploadedBy: batchId ? undefined : firebase.user?.email,
      syncStatus: batchId ? undefined : AggregatedSyncStatusEnum.ERROR,
    };

    const keys: string[] = ['uploadedBy', 'syncStatus'];
    Object.keys(previousState || {}).forEach((key) => {
      if (keys.includes(key)) {
        filter[key] = previousState[key] as any;
      }
    });

    return {
      query: {
        searchKey: undefined,
        page: 1,
        perPage: 10,
        startDate: batchId ? undefined : startDate.toISOString(true),
        endDate: batchId ? undefined : endDate.toISOString(true),
        filter,
      },
    };
  }, [batchId, startDate, endDate, firebase.user, previousState]);

  return initialFilterState;
}
