import { Box, CircularProgress, SxProps } from "@mui/material";

export type ImageProps = {
  sx?: SxProps;
  src: string;
  alt?: string;
  onClick?: () => void;
  loading?: boolean;
  width?: string;
  height?: string;
}

export function Image(props: ImageProps) {
  const {
    sx,
    src,
    alt,
    onClick,
    loading,
    width,
    height,
  } = props;

  if (loading) {
    return (
      <Box sx={sx}>
        <CircularProgress
          style={{
            fontSize: "20px",
            margin: "auto",
          }}
          size={50}
        />
      </Box >
    );
  }
  return (
    <Box sx={sx}>
      <img
        onClick={onClick}
        src={src}
        alt={alt}
        width={width}
        height={height}
      />
    </Box>
  );
};

export default Image;
