import moment, { Moment } from "moment-timezone";
import React from "react";

export type ErrorProps = {
  errorCode?: string;
  message: string;
  timestamp?: Moment;
}

export function useErrorHandler() {
  const [error, setError] = React.useState<ErrorProps>();
  const [count, setCount] = React.useState(1);

  const setErrorFn = React.useCallback((errorProps?: ErrorProps) => {
    if (!errorProps || !errorProps?.message) {
      setError(undefined);
      return;
    }

    const timestamp = moment(errorProps.timestamp || undefined).tz('Asia/Manila');
    setError((curr) => {
      if (curr?.message === errorProps.message) {
        setCount((x) => x + 1);
      } else {
        setCount(1);
      }
      return {
        errorCode: errorProps.errorCode,
        message: errorProps.message,
        timestamp,
      }
    });
  }, []);

  return {
    error,
    count,
    setError: setErrorFn,
  }
}
