import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import Path from './enums/path';
import { useApp } from './hooks/useApp';
import { FirebaseInit } from './hooks/useFirebase';
import { useRoutes } from './hooks/useRoutes';
import useTheme from './hooks/useTheme';
import { DefaultPapge } from './pages/default.page';
import { LoadingPage } from './pages/loading.page';
import { NotFoundPage } from './pages/not-found.page';

function App() {
  const App = useApp();
  const Theme = useTheme();
  const { routes } = useRoutes();

  return (
    <>
      <CssBaseline />
      <App.Provider>
        <FirebaseInit />
        <Theme.Provider>
          <LoadingPage>
            <Routes>
              <Route path={Path.Slash} element={<DefaultPapge />} />
              {routes}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </LoadingPage>
        </Theme.Provider>
      </App.Provider>
    </>
  );
}

export default App;
