import Path from 'enums/path';
import { Role } from 'enums/roles';
import { useAppContext } from 'hooks/useApp';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export type ProtectedProps = {
  children?: any;
  roles?: Role[];
  redirectTo?: string;
  noAccessElement?: JSX.Element; // Element to show if no access
};

export function Protected(props: ProtectedProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { firebase } = useAppContext();
  const {
    redirectTo = Path.Auth,
    children,
    roles,
    noAccessElement = <></>,
  } = props;

  // Check if user has access to page, display "no access page"
  const page = React.useMemo(() => {
    const pageRoles = [Role.ADMIN, ...(roles || [])];
    const userRoles: string[] =
      ((firebase.claims?.claims || {}) as any).roles || [];
    let allow = pageRoles.some((pr) => userRoles.includes(pr));
    if (pageRoles.includes(Role.LOGGEDIN) && firebase.claims && firebase.user) {
      allow = true;
    }
    return allow ? children : noAccessElement;
  }, [firebase, children, roles, noAccessElement]);

  // Redirect user if not logged in
  React.useEffect(() => {
    if (firebase.loaded && !firebase.user) {
      const redirectPath = encodeURIComponent(location.pathname);
      console.log('path', location.pathname);
      navigate(`${redirectTo}?redirect=${redirectPath}`);
    }
  }, [firebase, location, redirectTo, navigate]);

  return <>{firebase.user ? page : noAccessElement}</>;
}
