import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  Snackbar, SnackbarProps
} from "@mui/material";
import React from "react";

export interface AlertProps {
  message?: string,
  snackbarProps?: SnackbarProps;
  alertProps?: MuiAlertProps;
  children?: any;
}

export function Alert(props: AlertProps) {
  const { message, snackbarProps, alertProps, children } = props;
  const [open, setOpen] = React.useState(Boolean(message));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      autoHideDuration={6000}
      open={open}
      {...snackbarProps}
      onClose={handleClose}
      action={(
        <IconButton
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {children ? children : (
        <MuiAlert
          severity="success"
          sx={{ width: '100%' }}
          {...alertProps}
          onClose={handleClose}
        >
          {message}
        </MuiAlert>
      )}
    </Snackbar>
  )
}