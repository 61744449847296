import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "transactionId",
      accessorKey: "transactionId",
      header: "Transaction ID",
    },
    {
      id: "shiftReportId",
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: "dsrId",
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: "subsidiary",
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "stationName",
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: "shiftCode",
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: "dateTime",
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>
    },
    {
      id: "location",
      accessorKey: "location",
      header: "Location",
    },
    {
      id: "pumpCode",
      accessorKey: "pumpCode",
      header: "Pump Code",
    },
    {
      id: "itemCode",
      accessorKey: "itemCode",
      header: "Item Code",
    },
    {
      id: "product",
      accessorKey: "product",
      header: "Product",
    },
    {
      id: "paymentMode",
      accessorKey: "paymentMode",
      header: "Payment Mode",
    },
    {
      id: "transactionType",
      accessorKey: "transactionType",
      header: "Transaction Type",
    },
    {
      id: "amount",
      accessorKey: "amount",
      header: "Amount",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>
    },
    {
      id: "quantity",
      accessorKey: "quantity",
      header: "Quantity",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>
    },
  ];

  return columns;
}
