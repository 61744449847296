import { CenteredLayout } from "../components/layout/twentyfour/centered";
import { LoginModule } from "../modules/auth/login/login.module";

export function AuthPage() {
  return (
    <CenteredLayout>
      <LoginModule />
    </CenteredLayout>
  );
};
