import { DefaultLayout } from '../components/layout/twentyfour/default';
import { SettlementImportModule } from '../modules/settlement/settlement-import.module';

export function SettlementImportPage() {
  return (
    <DefaultLayout>
      <SettlementImportModule />
    </DefaultLayout>
  )
}
