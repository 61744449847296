import { Typography } from '@mui/material';
import React from 'react';
import { CenteredLayout } from '../components/layout/twentyfour/centered';
import { DefaultLayout } from '../components/layout/twentyfour/default';
import { useAppContext } from '../hooks/useApp';


export function NotFoundPage() {
  const { firebase } = useAppContext();

  const Layout = React.useMemo(() => {
    return firebase.loaded && firebase.user ? DefaultLayout : CenteredLayout;
  }, [firebase]);

  return (
    <Layout>
      <Typography variant="h4">
        Page not found
      </Typography>
    </Layout>
  )
}
