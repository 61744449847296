import {
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar
} from '@mui/material';
import { useRoutes } from 'hooks/useRoutes';
import log from 'loglevel';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Protected } from '../../commons/protected/protected';

export type SideBarProps = {
  open: boolean;
};

export const drawerWidth = 240;

export function SideBar(props: SideBarProps) {
  const navigate = useNavigate();
  const { list } = useRoutes();
  const location = useLocation();
  const { open } = props;

  const onClickMenu: React.MouseEventHandler<HTMLLIElement> = React.useCallback(
    (event) => {
      const target = event.currentTarget;
      const navPath = target.getAttribute('data-path');
      if (!navPath) {
        log.error(`no navigation path`);
        return;
      }
      navigate(navPath);
    },
    [navigate]
  );

  const menuItems = React.useMemo(() => {
    const items: JSX.Element[] = [];
    list.forEach((item, index) => {
      if (!item.menu) return;

      items.push(
        <Protected roles={item.roles} key={index}>
          <MenuItem
            onClick={onClickMenu}
            data-path={item.urlPath}
            sx={{
              backgroundColor:
                location.pathname === item.urlPath ? '#f0f0f0' : 'transparent',
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={{ textWrap: 'wrap' }}>{item.label}</ListItemText>
          </MenuItem>
        </Protected>
      );
    });
    return items;
  }, [list, onClickMenu, location.pathname]);

  return (
    <Drawer
      open={open}
      variant="persistent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <MenuList>{menuItems}</MenuList>
    </Drawer>
  );
}
