import axios from 'axios';
import log from 'loglevel';
import React from 'react';
import { Environment } from '../environments/index.type';
import { useFirebase } from './useFirebase';

export type UseApiProps = {
  env?: Environment,
  firebase: ReturnType<typeof useFirebase>,
}

export function useApi(props: UseApiProps) {
  const { env, firebase } = props;
  const [initialized, setInitialized] = React.useState(false);
  const [error, setError] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState(false);

  const refreshToken = React.useCallback(async (force?: boolean) => {
    if (!firebase.token) return;
    if (loading) return;
    setLoading(true);
    axios.defaults.baseURL = env?.apiUrl;
    axios.defaults.headers.common['Authorization'] = firebase.token;
    setLoading(false);
  }, [env, firebase.token, loading]);

  // subsribe
  React.useEffect(() => {
    // request
    const irequest = axios.interceptors.request.use((cfg) => {
      log.debug('sending request:', cfg);
      return cfg;
    }, (err) => {
      log.error('request error:', err);
      return Promise.reject(err);
    });

    // response
    const iresponse = axios.interceptors.response.use((r) => {
      log.debug('response received:', r);
      return r;
    }, (err) => {
      log.error('response error:', err);
      log.error('response error:', err.response?.status);
      if (err.response && (
        err.response.status === 401
        || err.response.status === 403
        || err.code === 'ERR_NETWORK'
      )) {
        firebase.logoutHandler();
      }
      return Promise.reject(err);
    });

    return () => {
      // unsubscribe listeners
      if (irequest) axios.interceptors.request.eject(irequest);
      if (iresponse) axios.interceptors.response.eject(iresponse);
    }
  }, [firebase]);

  // Set Axios Defaults
  React.useEffect(() => {
    if (!firebase.token) return;
    setInitialized(false);
    log.debug('setting axios instance');

    refreshToken()
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setInitialized(true);
      });
  }, [refreshToken, firebase.token]);

  return {
    initialized,
    error,
  }
}
