import { Box, Alert as MuiAlert, AlertTitle as MuiAlertTitle, Stack, styled } from '@mui/material';
import React from 'react';
import { useAppContext } from '../../../hooks/useApp';
import { Alert } from '../../commons/alert/alert';
import { Header } from './header';
import { SideBar, drawerWidth } from './sidebar';

// reference: https://mui.com/material-ui/react-drawer/
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export type DefaultLayoutProps = {
  sidebar?: boolean;
  children?: any;
};

export function DefaultLayout(props: DefaultLayoutProps) {
  const { children, sidebar: defaultSide = true } = props;
  const { errorHandler } = useAppContext();

  const [sidebar] = React.useState(defaultSide);

  const errorDescription = React.useMemo(() => {
    let helper = '';
    if (errorHandler.count > 1) {
      helper += `[ ${errorHandler.count} ] `;
    }
    helper += errorHandler.error?.timestamp?.format('MMM D, YYYY hh:mm A');
    return helper;
  }, [errorHandler.count, errorHandler.error]);

  return (
    <>
      <Header sidebar={sidebar} />
      <Stack direction='row'>
        <SideBar open={sidebar} />
        <Main open={sidebar}>
          <Box component="main" width='100%'>
            {children}
          </Box>
        </Main>
      </Stack>
      <Alert
        alertProps={{ severity: 'error' }}
        message={errorHandler.error?.message}
      >
        <MuiAlert
          severity="error"
          variant='filled'
        >
          <MuiAlertTitle>
            {errorHandler.error?.message}
          </MuiAlertTitle>
          {errorDescription}
        </MuiAlert>
      </Alert>
    </>
  );
}
