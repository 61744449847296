import { DefaultLayout } from "../components/layout/twentyfour/default";
import { AggregatedSummaryModule } from "../modules/aggregator/aggregated-summary.module";

export function AggregatedSummaryPage() {
  return (
    <DefaultLayout>
      <AggregatedSummaryModule />
    </DefaultLayout>
  );
};
