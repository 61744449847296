import React from "react";

type initState = {
  name: string;
  state: any;
}

export function useInitStates() {
  const states = React.useRef<initState[]>([]);

  const getState = (name: string) => {
    const y = states.current.find((x) => x.name === name);
    return y ? y.state : undefined;
  }

  const setState = (name: string, state: any) => {
    const y = states.current.find((x) => x.name === name);
    if (!y) {
      if (typeof state === 'function') {
        states.current.push({ name, state: state() });
      } else {
        states.current.push({ name, state });
      }
    } else {
      if (typeof state === 'function') {
        y.state = state(y.state);
      } else {
        y.state = state;
      }
    }
  }

  return {
    getState,
    setState,
  }
}
