import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "pumpId",
      accessorKey: "pumpId",
      header: "Pump ID",
    },
    {
      id: "shiftReportId",
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: "dsrId",
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: "subsidiary",
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "stationName",
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: "date",
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "dateTime",
      accessorKey: "dateTime",
      header: "Date/Time",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "location",
      accessorKey: "location",
      header: "Location",
    },
    {
      id: "pumpCode",
      accessorKey: "pumpCode",
      header: "Pump Code",
    },
    {
      id: "itemCode",
      accessorKey: "itemCode",
      header: "Item Code",
    },
    {
      id: "productName",
      accessorKey: "productName",
      header: "Product Name",
    },
    {
      id: "totalizerBeginning",
      accessorKey: "totalizerBeginning",
      header: "Totalizer Beginning",
      cell: (info) => <DisplayFloat decimalPlaces={3}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "totalizerClosing",
      accessorKey: "totalizerClosing",
      header: "Totalizer Closing",
      cell: (info) => <DisplayFloat decimalPlaces={3}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "totalizerDifference",
      accessorKey: "totalizerDifference",
      header: "Totalizer Difference",
      cell: (info) => <DisplayFloat decimalPlaces={3}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "recordedVolume",
      accessorKey: "recordedVolume",
      header: "Recorded Volume",
      cell: (info) => <DisplayFloat decimalPlaces={3}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "variance",
      accessorKey: "variance",
      header: "Variance",
      cell: (info) => <DisplayFloat decimalPlaces={3}>{info.getValue() as string}</DisplayFloat>,
    },
    {
      id: "percentVariance",
      accessorKey: "percentVariance",
      header: "Percent Variance",
      cell: (info) => <DisplayFloat isPercent decimalPlaces={2}>{info.getValue() as string}</DisplayFloat>,
    },
  ];

  return columns;
}
