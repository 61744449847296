import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import moment from 'moment-timezone';
import React from "react";
import { LocationAutocomplete } from "../../components/commons/form/autocomplete/location-autocomplete";
import { useFormController } from "../../components/commons/form/controller/form-controller";
import { DatePicker } from "../../components/commons/form/date-picker/date-picker";
import { Select } from "../../components/commons/form/select/select";
import { TextField } from "../../components/commons/form/textfield/textfield";
import { SubsidiaryEnum } from "../../enums/subsidiary";

export type DsrRecordsFilterProps = {
}

export function DsrRecordsFilter(props: DsrRecordsFilterProps) {
  const formController = useFormController();

  const [subsidiary, setSubsidiary] = React.useState(formController.getValues().subsidiary);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const momentStart = React.useMemo(() => moment(startDate).tz('Asia/Manila'), [startDate]);
  const momentEnd = React.useMemo(() => moment(endDate).tz('Asia/Manila'), [endDate]);

  const onChange = React.useCallback((data: any) => {
    if (data.subsidiary !== subsidiary) {
      setSubsidiary(data.subsidiary === '' ? undefined : data.subsidiary);
    }
    if (data.startDate !== momentStart.toISOString(true)) {
      setStartDate(data.startDate);
    }
    if (data.endDate !== momentEnd.toISOString(true)) {
      setEndDate(data.endDate);
    }
  }, [subsidiary, momentStart, momentEnd]);

  React.useEffect(() => {
    onChange(formController.getValues());
    const { unsubscribe } = formController.watch(onChange);
    return () => unsubscribe();
  }, [formController, onChange]);

  return (
    <>
      <Box sx={{ pt: "10px" }}>
        <Stack spacing={2} direction='row'>
          <Select
            name="subsidiary"
            label="Subsidiary"
            options={['', ...Object.values(SubsidiaryEnum)]}
            selectProps={{ sx: { minWidth: '100px' } }}
          />
          <LocationAutocomplete
            name="location"
            label="Station"
            subsidiary={subsidiary}
          />
          <TextField
            name="shiftCode"
            label="Shift"
            textFieldProps={{ sx: { maxWidth: '70px' } }}
          />
          <DatePicker
            name="startDate"
            label="Start Date"
            datePickerProps={{
              maxDate: momentEnd,
              sx: { maxWidth: '160px' }
            }}
            dateModifier={(d) => d.startOf('day')}
          />
          <DatePicker
            name="endDate"
            label="End Date"
            datePickerProps={{
              minDate: momentStart,
              sx: { maxWidth: '160px' }
            }}
            dateModifier={(d) => d.endOf('day')}
          />
        </Stack>
      </Box>
    </>
  );
}
