import Swal, { SweetAlertOptions } from 'sweetalert2';
import Colors from '../enums/colors';

/**
 * @deprecated
 */
export function useSwal() {

  const swal = Swal.mixin({
    allowOutsideClick: false,
    showConfirmButton: true,
    cancelButtonText: 'Close',
    confirmButtonColor: Colors.Blue,
  });

  const showSuccess = async (options: SweetAlertOptions) => swal.fire({
    icon: 'success',
    confirmButtonText: 'Ok',
    showCancelButton: false,
    ...options,
  });

  const showWarning = async (options: SweetAlertOptions) => swal.fire({
    icon: 'warning',
    confirmButtonText: 'Ok',
    showCancelButton: false,
    ...options,
  });

  const showConfirm = async (options: SweetAlertOptions) => swal.fire({
    icon: 'info',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    ...options,
  });

  const showError = async (options: SweetAlertOptions) => swal.fire({
    icon: 'error',
    confirmButtonText: 'Retry',
    showCancelButton: true,
    ...options,
  });

  return {
    fire: swal.fire,
    showSuccess,
    showWarning,
    showConfirm,
    showError,
  };
};
