import {
  FormControlProps
} from '@mui/material';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { useController } from 'react-hook-form';
import { useFormController } from '../controller/form-controller';

export interface TextFieldProps {
  name: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  formControlProps?: FormControlProps;
  textFieldProps?: MuiTextFieldProps;
}

export function TextField(props: TextFieldProps) {
  const {
    name,
    label,
    formControlProps,
    textFieldProps,
  } = props;

  const { control } = useFormController();
  const { field } = useController({
    name,
    control,
  });

  const [value, setValue] = React.useState<string>(field.value);

  return (
    <MuiTextField
      {...field}
      {...textFieldProps}
      InputLabelProps={{ shrink: true }}
      label={label}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        field.onChange(e);
      }}
    />
  );
};
