import { ColumnDef } from "@tanstack/react-table";
import { DisplayDate } from "../../../components/commons/data-display/DisplayDate";
import { DisplayFloat } from "../../../components/commons/data-display/DisplayFloat";

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: "Created At",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      id: "cashBreakdownId",
      accessorKey: "cashBreakdownId",
      header: "Cash Breakdown ID",
    },
    {
      id: "shiftReportId",
      accessorKey: "shiftReportId",
      header: "Shift Report ID",
    },
    {
      id: "dsrId",
      accessorKey: "dsrId",
      header: "DSR ID",
    },
    {
      id: "subsidiary",
      accessorKey: "subsidiary",
      header: "Subsidiary",
    },
    {
      id: "stationCode",
      accessorKey: "stationCode",
      header: "Station Code",
    },
    {
      id: "stationName",
      accessorKey: "stationName",
      header: "Station Name",
    },
    {
      id: "shiftCode",
      accessorKey: "shiftCode",
      header: "Shift Code",
    },
    {
      id: "date",
      accessorKey: "date",
      header: "Date",
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    // {
    //   id: 'dateTime',
    //   accessorKey: "dateTime",
    //   header: "Date/Time",
    //   cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    // },
    // {
    //   id: 'category',
    //   accessorKey: "category",
    //   header: "Category",
    // },
    // {
    //   id: 'denomination',
    //   accessorKey: "denomination",
    //   header: "Denomination",
    // },
    // {
    //   id: 'pieces',
    //   accessorKey: "pieces",
    //   header: "Pieces",
    //   cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    // },
    {
      id: "amount",
      accessorKey: "amount",
      header: "Amount",
      cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
    },
  ];

  return columns;
}
