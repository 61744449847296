import React from 'react';
import dev from './env.dev';
import local from './env.local';
import prod from './env.prod';
import { Environment, Stage } from './index.type';

export function useEnvironment() {
  const STAGE = (process.env.REACT_APP_STAGE || 'local') as Stage;

  const [env, setEnv] = React.useState<Environment>();

  React.useEffect(() => {
    if (env) return;
    const environments: { [k in Stage]?: Environment } = {
      [Stage.DEV]: dev,
      [Stage.LOCAL]: local,
      [Stage.PROD]: prod,
    };
    const e = environments[STAGE];
    if (!e) {
      throw new Error(`Unknown environment: ${STAGE}`);
    }
    setEnv(e);
  }, [STAGE, env]);

  return env;
}

export default useEnvironment;
