import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment, { Moment } from 'moment-timezone';
import React from "react";
import { useController } from "react-hook-form";
import { useFormController } from "../controller/form-controller";

export type DatePickerProps = {
  name: string;
  label?: string;
  timezone?: string;
  datePickerProps?: MuiDatePickerProps<Moment>;
  dateModifier?: (date: Moment) => void;
}

export function DatePicker(props: DatePickerProps) {
  const {
    name,
    label,
    timezone = 'Asia/Manila',
    datePickerProps,
    dateModifier,
  } = props;

  const { control } = useFormController();
  const { field } = useController({ name, control });

  const now = moment().tz(timezone);
  let initDate = moment(field.value || null).tz(timezone);
  if (!initDate.isValid()) {
    console.warn(`Invalid date from '${name}': ${field.value}`);
    initDate = now;
  }

  if (dateModifier) {
    dateModifier(initDate);
  }

  const [date, setDate] = React.useState<Moment>(initDate);

  const handleChange = React.useCallback((value: Moment | null) => {
    const newValue = value ? value : now;
    if (dateModifier) dateModifier(newValue);
    field.onChange(newValue.toISOString(true));
    setDate(newValue);
  }, [dateModifier, field, now]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          {...datePickerProps}
          name={name}
          label={label || name}
          timezone={timezone}
          value={date}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </>
  )
}