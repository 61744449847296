import { ColumnDef } from '@tanstack/react-table';
import { DisplayDate } from '../../../components/commons/data-display/DisplayDate';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export function useColumns() {
  const columns: ColumnDef<unknown>[] = [
    {
      accessorKey: 'shiftCode',
      header: 'Shift Code',
    },
    {
      accessorKey: 'shiftStart',
      header: 'Shift Start',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      accessorKey: 'shiftEnd',
      header: 'Shift End',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
    {
      accessorKey: 'createdAt',
      header: 'Upload Date',
      cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
    },
  ];

  return columns;
}
