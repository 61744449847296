import { Grid, Stack } from '@mui/material';
import { DataTable } from 'components/commons/data-table/data-table';
import { DataTableControl } from 'components/commons/data-table/data-table-control';
import { DataTablePagination } from 'components/commons/data-table/data-table-pagination';
import Intro from 'components/commons/intro/intro';
import { useParams } from 'react-router-dom';
import { getAggregated } from '../../apis/aggregator.api';
import { DataTableDateRange } from '../../components/commons/data-table/data-table-date-range';
import { columns } from './aggregated-list-column';
import { AggregatedListExport } from './aggregated-list-export';
import { AggregatedListFilter } from './aggregated-list-filter';
import { useInitialFilterState } from './aggregated-list-filter.state';

export type AggregatedListProps = {};

export function AggregatedListModule(props: AggregatedListProps) {
  const initialFilterState = useInitialFilterState();
  const { batchId } = useParams();

  return (
    <>
      <Intro title="Aggregated List" subtitle="" />
      <DataTableControl
        columns={columns}
        listFn={getAggregated}
        initialApiProps={initialFilterState}
        autoCommit={Boolean(batchId)}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <AggregatedListFilter />
                <DataTableDateRange showDateText />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end">
                <AggregatedListExport />
              </Stack>
            </Grid>
          </Grid>

          <DataTable
            initialTableState={{
              columnVisibility: {
                externalId: false,
                locationId: false,
                itemId: false,
                customerId: false,
                uomId: false,
                batchId: false,
                uploadedBy: false,
              },
            }}
          />

          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
