import {
  AppBar,
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import Image from 'components/commons/image/image';
import Path from 'enums/path';
import { useAppContext } from 'hooks/useApp';
import { HeaderLogo } from 'images';
import React from 'react';
import { useNavigate } from 'react-router';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export type HeaderProps = {
  sidebar?: boolean;
};

export function Header(props: HeaderProps) {
  const { env, firebase } = useAppContext();
  const navigate = useNavigate();

  const UserIcon = React.useCallback(() => {
    if (!firebase.user) return <></>;
    const name = firebase.user.displayName || firebase.user.email || '';
    return (
      <IconButton onClick={() => navigate(Path.UserProfile)}>
        <Avatar alt={name} src={firebase.user.photoURL || ''} />
      </IconButton>
    );
  }, [firebase.user, navigate]);

  return (
    <>
      <AppBar elevation={1} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Image src={HeaderLogo} width="50px" sx={{ mr: 2 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {`${env?.label} Aggregator`.trim()}
          </Typography>
          <Stack direction="row" spacing={1}>
            <UserIcon />
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
}
