import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import moment from 'moment-timezone';

const DsrReceipt = ({ data }: { data: Record<string, string> }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {Object.entries(data).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  width: '80px',
                }}
              >
                {key}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'left',
                }}
              >
                {key === 'uploaded_at'
                  ? moment(value).tz('Asia/Manila').format('MMMM D, YYYY h:mm:ss A')
                  : value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DsrReceipt;
