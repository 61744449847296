import { Button, Grid, Typography } from '@mui/material';
import { useAppContext } from 'hooks/useApp';

export function UserProfileModule() {
  const { firebase } = useAppContext();

  console.log('user', firebase.user);

  return (
    <Grid container>
      <Grid item xs>
        <Typography variant="h4">{firebase.user?.displayName}</Typography>
        <Typography variant="body1">{firebase.user?.email}</Typography>
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="error"
          onClick={() => firebase.logoutHandler()}
        >
          Logout
        </Button>
      </Grid>
    </Grid>
  );
}
