import axios from "axios";
import ApiPath from "../enums/api-path";
import { ListApiFn, ListApiProps } from "./api.type";

export const getSettlements: ListApiFn = async (props: ListApiProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.Settlement}`, { params: query });
  return res;
};

export type ImportSettlementsProps = {
  data: File[];
  processor: any;
}

export const importSettlement = async (props: ImportSettlementsProps) => {
  const { data, processor } = props;
  const formData = new FormData();
  data.forEach((file) => {
    formData.append('data', file);
  });
  formData.append('processor', processor);
  const res = await axios.post(`${ApiPath.Settlement}/import`, formData);
  return res.data;
};
