import { Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type BackLinkProps = {
  list: {
    text: string;
    path: string;
  }[];
}

export function BackLink(props: BackLinkProps) {
  const { list } = props;

  const navigate = useNavigate();

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const href = e.currentTarget.getAttribute('href');
    if (href) navigate(href);
  }

  const crumbs = list.map(({ text, path }) => (
    <Link
      key={path}
      underline="hover"
      color="inherit"
      href={path}
      onClick={onClick}
    >{text}</Link>
  ));

  return (
    <Breadcrumbs >
      {crumbs}
    </Breadcrumbs>
  );
};
