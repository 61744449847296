import { ColumnDef } from '@tanstack/react-table';
import { DisplayDate } from '../../components/commons/data-display/DisplayDate';
import { DisplayFloat } from '../../components/commons/data-display/DisplayFloat';
import { DisplayLongText } from '../../components/commons/data-display/DisplayLongText';

// reference: https://tanstack.com/table/latest/docs/guide/column-defs

export const columns: ColumnDef<unknown>[] = [
  {
    id: "externalId",
    accessorKey: "externalId",
    header: "External ID",
  },
  {
    id: "subsidiary",
    accessorKey: "subsidiary",
    header: "Subsidiary",
  },
  {
    id: "dsrId",
    accessorKey: "dsrId",
    header: "DSR ID",
  },
  {
    id: "postingDate",
    accessorKey: "postingDate",
    header: "Posting Date",
    cell: (info) => <DisplayDate timeFormat={null}>{info.getValue() as string}</DisplayDate>,
  },
  {
    id: "locationId",
    accessorKey: "locationId",
    header: "Location ID",
  },
  {
    id: "stationCode",
    accessorKey: "stationCode",
    header: "Station Code",
  },
  {
    id: "stationName",
    accessorKey: "stationName",
    header: "Station Name",
  },
  {
    id: "itemId",
    accessorKey: "itemId",
    header: "Item ID",
  },
  {
    id: "salesItemCode",
    accessorKey: "salesItemCode",
    header: "Item Code",
  },
  {
    id: "salesProductName",
    accessorKey: "salesProductName",
    header: "Product Name",
  },
  {
    id: "modeOfPayment",
    accessorKey: "modeOfPayment",
    header: "Mode Of Payment",
  },
  {
    id: "transactionType",
    accessorKey: "transactionType",
    header: "Transaction Type",
  },
  {
    id: "customerId",
    accessorKey: "customerId",
    header: "Customer ID",
  },
  {
    id: "referenceName",
    accessorKey: "referenceName",
    header: "Reference Name",
  },
  {
    id: "approvalCode",
    accessorKey: "approvalCode",
    header: "Approval Code",
  },
  {
    id: "unitPrice",
    accessorKey: "unitPrice",
    header: "Unit Price",
    cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
  },
  {
    id: "sumOfOtherDiscount",
    accessorKey: "sumOfOtherDiscount",
    header: "Sum Of Other Discount",
    cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
  },
  {
    id: "sumOfLbVat",
    accessorKey: "sumOfLbVat",
    header: "Sum Of LB Vat",
    cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
  },
  {
    id: "sumOfAmountVatEx",
    accessorKey: "sumOfAmountVatEx",
    header: "Sum Of Amount (Vat Ex)",
    cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
  },
  {
    id: "sumOfQuantity",
    accessorKey: "sumOfQuantity",
    header: "Sum Of Quantity",
    cell: (info) => <DisplayFloat>{info.getValue() as string}</DisplayFloat>,
  },
  {
    id: "uom",
    accessorKey: "uom",
    header: "UOM",
  },
  {
    id: "uomId",
    accessorKey: "uomId",
    header: "Uom ID",
  },
  {
    id: "batchId",
    accessorKey: "batchId",
    header: "Batch ID",
  },
  {
    id: "createdAt",
    accessorKey: "createdAt",
    header: "Created At",
    cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
  },
  {
    id: "syncStatus",
    accessorKey: "syncStatus",
    header: "Sync Status",
  },
  {
    id: "syncedAt",
    accessorKey: "syncedAt",
    header: "Synced At",
    cell: (info) => <DisplayDate>{info.getValue() as string}</DisplayDate>,
  },
  {
    id: "errorMessage",
    accessorKey: "errorMessage",
    header: "Error Message",
    cell: (info) => <DisplayLongText>{info.getValue() as string}</DisplayLongText>,
  },
  {
    id: "uploadedBy",
    accessorKey: "uploadedBy",
    header: "Uploaded By",
  },
];
