import React from 'react';
import useEnvironment from '../environments';
import { useApi } from './useApi';
import { useErrorHandler } from './useErrorHandler';
import { useFirebase } from './useFirebase';
import { useInitStates } from './useInitStates';

type AppContextType = {
  theme?: string;
  env: ReturnType<typeof useEnvironment>;
  firebase: ReturnType<typeof useFirebase>;
  api: ReturnType<typeof useApi>;
  errorHandler: ReturnType<typeof useErrorHandler>;
  initState: ReturnType<typeof useInitStates>;
};

let AppContext: React.Context<AppContextType>;

export function useApp() {
  const env = useEnvironment();
  const errorHandler = useErrorHandler();
  const firebase = useFirebase({ env });
  const api = useApi({ env, firebase });

  const data: AppContextType = {
    theme: env?.id,
    env,
    firebase,
    api,
    errorHandler,
    initState: useInitStates(),
  };

  AppContext = React.createContext(data);

  const Provider = (props: { children: any }) => (
    <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
  );

  return {
    Provider,
  };
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error(
      `useAppContext must be used within useApp().Provider Context`
    );
  }
  return context;
}
