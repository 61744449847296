import { DefaultLayout } from "../components/layout/twentyfour/default";
import { AggregatorImportFilesModule } from "../modules/aggregator/aggregator-import-files.module";

export function AggregatorImportFilesPage() {
  return (
    <DefaultLayout>
      <AggregatorImportFilesModule />
    </DefaultLayout>
  );
};
