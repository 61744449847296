
import React from "react";
import { exportDsrExpenseList, GetDsrExpenseListProps } from "../../../apis/dsr.api";
import { FileDownload } from "../../../components/commons/file-download/file-download";
import { useFormController } from "../../../components/commons/form/controller/form-controller";

export type DsrExpenseExportProps = {};

export function DsrExpenseExport(props: DsrExpenseExportProps) {
  const formController = useFormController();

  const [data, setData] = React.useState<any>();
  const [query, setQuery] = React.useState<GetDsrExpenseListProps['query']>({});
  const [filename, setFilename] = React.useState('dsr_expense.csv');

  const onDownload = React.useCallback(async () => {
    return exportDsrExpenseList({ query })
  }, [query]);

  const onChange = React.useCallback((data: any) => {
    setData(data);
    setQuery({
      subsidiary: data.subsidiary === '' ? undefined : data.subsidiary,
      stationCode: data.location?.stationCode,
      shiftCode: data.shiftCode,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }, []);

  React.useEffect(() => {
    const initData = formController.getValues();
    onChange(initData);
    const { unsubscribe } = formController.watch(onChange);
    return () => unsubscribe();
  }, [formController, onChange]);

  React.useEffect(() => {
    let filename = ['dsr_expense'];
    if (data?.dateYear) filename.push(data.dateYear);
    if (data?.dateMonth) filename.push(data.dateMonth);
    if (data?.subsidiary && data?.subsidiary !== '') filename.push(data.subsidiary);
    if (data?.location?.stationCode) filename.push(data.location?.stationCode);
    setFilename(`${filename.join('-')}.csv`);
  }, [data]);

  return (
    <FileDownload
      variant='contained'
      filename={filename}
      onDownload={onDownload}
    >Export CSV</FileDownload>
  );
}
