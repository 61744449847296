import { DefaultLayout } from '../components/layout/twentyfour/default';
import { DsrSummaryModule } from '../modules/dsr/dsr-summary.module';

export function DsrSummaryPage() {
  return (
    <DefaultLayout>
      <DsrSummaryModule />
    </DefaultLayout>
  );
}
