export enum Role {
  ADMIN = 'admin',
  LOGGEDIN = 'loggedin', // Anyone logged in
  E2E = 'e2e', // End-to-end accountant
  SH = 'sh', // Station head
  AR = 'ar', // accounts receivable
}

export type RoleType = {
  value: Role;
  label?: string;
}

export const RoleList: RoleType[] = [
  {
    value: Role.ADMIN,
    label: 'Administrator',
  },
  {
    value: Role.E2E,
    label: 'End-to-end Accountant',
  },
  {
    value: Role.SH,
    label: 'Station Head',
  },
  {
    value: Role.AR,
    label: 'AR Accountant',
  },
];
