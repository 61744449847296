import axios from 'axios';
import ApiPath from '../enums/api-path';
import { BasicQueryApi, ListApiFn, ListApiProps } from './api.type';

export type ImportDsrProps = {
  data: any;
};

export type GetDSRShiftByStationCodeProps = {
  stationCode: number;
  date: string;
};
export interface BasicDsrQueryApi extends BasicQueryApi {
  shiftReportId?: string;
  dsrId?: string;
  stationCode?: string;
  subsidiary?: string;
  shiftCode?: string;
}

export const importDsr = async (props: ImportDsrProps) => {
  const { data } = props;
  const formData = new FormData();
  formData.append('data', data);
  const res = await axios.post(`${ApiPath.Dsr}/import`, formData);
  return res.data;
};

export type GetDsrShiftListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrShiftList = async (props: GetDsrShiftListProps) => {
  const { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrShift}`, { params: query });
  return res;
};
export const exportDsrShiftList = async (props?: GetDsrShiftListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrShiftExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrPumpListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrPumpList: ListApiFn = async (props: GetDsrPumpListProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrPump}`, { params: query });
  return res;
};
export const exportDsrPumpList = async (props?: GetDsrPumpListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrPumpExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrItemReceiptListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrItemReceiptList: ListApiFn = async (props: GetDsrItemReceiptListProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrItemReceipt}`, { params: query });
  return res;
};
export const exportDsrItemReceiptList = async (props?: GetDsrItemReceiptListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrItemReceiptExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrInventoryListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrInventoryList: ListApiFn = async (props: GetDsrInventoryListProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrInventory}`, { params: query });
  return res;
};
export const exportDsrInventoryList = async (props?: GetDsrInventoryListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrInventoryExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrExpenseListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrExpenseList: ListApiFn = async (props: GetDsrExpenseListProps) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrExpense}`, { params: query });
  return res;
};
export const exportDsrExpenseList = async (props?: GetDsrExpenseListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrExpenseExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrDepositListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrDepositList: ListApiFn = async (
  props: GetDsrDepositListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrDeposit}`, { params: query });
  return res;
};
export const exportDsrDepositList = async (props?: GetDsrDepositListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrDepositExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrCollectionListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrCollectionList: ListApiFn = async (
  props: GetDsrCollectionListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrCollection}`, { params: query });
  return res;
};
export const exportDsrCollectionList = async (props?: GetDsrCollectionListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrCollectionExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrCashShortListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrCashShortList: ListApiFn = async (
  props: GetDsrCashShortListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrCashShort}`, { params: query });
  return res;
};
export const exportDsrCashShortList = async (props?: GetDsrCashShortListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrCashShortExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrCashOverListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrCashOverList: ListApiFn = async (
  props: GetDsrCashOverListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrCashOver}`, { params: query });
  return res;
};
export const exportDsrCashOverList = async (props?: GetDsrCashOverListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrCashOverExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrCashBreakdownListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrCashBreakdownList: ListApiFn = async (
  props: GetDsrCashBreakdownListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrCashBreakdown}`, { params: query });
  return res;
};
export const exportDsrCashBreakdownList = async (props?: GetDsrCashBreakdownListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrCashBreakdownExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrAttendanceListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrAttendanceList: ListApiFn = async (
  props: GetDsrAttendanceListProps
) => {
  let { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrAttendance}`, { params: query });
  return res;
};
export const exportDsrAttendanceList = async (props?: GetDsrAttendanceListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrAttendanceExport}`, {
    params: query,
    responseType: "blob",
  });
  return res;
};

export type GetDsrTransactionListProps = ListApiProps<BasicDsrQueryApi>;
export const getDsrTransactionList = async (props?: GetDsrTransactionListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrTransaction}`, { params: query });
  return res;
};
export const exportDsrTransactionList = async (props?: GetDsrTransactionListProps) => {
  let { query = {} } = props || {};
  const res = await axios.get(`${ApiPath.DsrTransactionExport}`, {
    params: query,
    responseType: 'blob',
  });
  return res;
};

export interface GetDsrShiftSummaryListQueryApi extends BasicQueryApi {
  stationCode?: string;
}
export interface GetDsrShiftSummaryListRecord {
  businessDate: string,
  shift1: number,
  shift2: number,
  shift3: number,
}
export type GetDsrShiftSummaryListProps = ListApiProps<GetDsrShiftSummaryListQueryApi>;
export const getDsrShiftSummaryList = async (props: GetDsrShiftSummaryListProps) => {
  const { query = {} } = props;
  const res = await axios.get(`${ApiPath.DsrShiftSummary}`, { params: query });
  return res;
};
