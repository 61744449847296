import { Box, CircularProgress, Typography } from '@mui/material';
import { useAppContext } from '../hooks/useApp';

export type LoadingPageProps = {
  children?: any;
};

export function LoadingPage(props: LoadingPageProps) {
  const { children } = props;
  const { firebase } = useAppContext();

  const loading = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CircularProgress color="primary" size={24} />
      <Typography marginLeft={2}>Loading...</Typography>
    </Box>
  )

  return <>{firebase.loaded ? children : loading}</>;
}
