import { Box, Divider, Typography } from '@mui/material';

export type IntroProps = {
  title: string;
  subtitle: string;
};

export function Intro(props: IntroProps) {
  const { title, subtitle } = props;

  return (
    <Box>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body1">{subtitle}</Typography>
      <Divider sx={{ mt: '5px', mb: '20px' }} />
    </Box>
  );
}

export default Intro;
