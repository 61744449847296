import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Grid, Stack } from '@mui/material';

import { DataTable } from 'components/commons/data-table/data-table';
import { DataTableControl } from 'components/commons/data-table/data-table-control';
import { DataTablePagination } from 'components/commons/data-table/data-table-pagination';
import Intro from 'components/commons/intro/intro';
import { useNavigate } from 'react-router-dom';
import { getAggregatedSummaryByStatus } from '../../apis/aggregator.api';
import { DataTableDateRange } from '../../components/commons/data-table/data-table-date-range';
import Path from '../../enums/path';
import { useColumns } from './aggregated-summary-column';
import { AggregatedSummaryExport } from './aggregated-summary-export';
import { AggregatedSummaryFilter } from './aggregated-summary-filter';
import { useInitialFilterState } from './aggregated-summary-filter.state';

export type AggregatedSummaryModuleProps = {};

export function AggregatedSummaryModule(props: AggregatedSummaryModuleProps) {
  const navigate = useNavigate();
  const columns = useColumns();
  const initialFilterState = useInitialFilterState();

  return (
    <>
      <Intro title="Aggregator" subtitle="Summary of uploaded transactions" />
      <DataTableControl
        columns={columns}
        listFn={getAggregatedSummaryByStatus}
        initialApiProps={{ query: initialFilterState }}
        autoCommit={false}
      >
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs>
              <Stack direction="row" spacing={1}>
                <AggregatedSummaryFilter />
                <DataTableDateRange showDateText />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="flex-end" spacing={2}>
                <AggregatedSummaryExport />
                <Button
                  variant="outlined"
                  onClick={() => navigate(Path.AggregatedImport)}
                  startIcon={<FileUploadIcon />}
                >
                  Import File
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <DataTable
            initialTableState={{
              columnVisibility: {
                batchId: false,
              },
            }}
          />
          <DataTablePagination />
        </Stack>
      </DataTableControl>
    </>
  );
}
