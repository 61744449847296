// CustomDialog.tsx
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  icon?: any;
  title: string;
  message: string;
  onPrimaryButtonClick?: () => void;
  primaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonText?: string;
  children?: ReactNode;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  icon,
  title,
  message,
  onPrimaryButtonClick,
  primaryButtonText,
  onSecondaryButtonClick,
  secondaryButtonText,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" textAlign="left">
        {icon && <div> {icon}</div>}
        {title}
      </DialogTitle>
      <DialogContent>
        {message}
        {children}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        {secondaryButtonText && (
          <Button
            onClick={onSecondaryButtonClick}
            color="primary"
            variant="contained"
            size="small"
          >
            {secondaryButtonText}
          </Button>
        )}
        {primaryButtonText && (
          <Button
            onClick={onPrimaryButtonClick}
            color="primary"
            variant="contained"
            size="small"
          >
            {primaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
