import { DefaultLayout } from '../components/layout/twentyfour/default';
import { SettlementListModule } from '../modules/settlement/settlement-list.module';

export function SettlementListPage() {
  return (
    <DefaultLayout>
      <SettlementListModule />
    </DefaultLayout>
  )
}
