import { ListApiProps } from 'apis/api.type';

export const initialFilterState: ListApiProps = {
  query: {
    searchKey: '',
    page: 1,
    perPage: 10,
    // filter: {
    //   stationBusinessName: '',
    //   paymentMethod: '',
    //   paymentProcessor: '',
    //   transactionDate: '',
    // },
  },
};
