import { DefaultLayout } from '../components/layout/twentyfour/default';
import { CustomerListModule } from '../modules/customer/customer-list.module';

export function CustomerListPage() {
  return (
    <DefaultLayout>
      <CustomerListModule />
    </DefaultLayout>
  )
}
